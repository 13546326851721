import {
  PLACES_REQUEST, PLACES_SUCCESS, PLACES_FAILURE
} from '../actions/place'

const places = (state = { isFetched: false, isFetching: false, items: [] }, action) => {
  switch (action.type) {
    case PLACES_REQUEST:
      return Object.assign({}, state, { isFetching: true })

    case PLACES_SUCCESS:
      return Object.assign({}, state, { isFetched: true, isFetching: false, items: action.items, errorMessage: '' })

    case PLACES_FAILURE:
      return Object.assign({}, state, { isFetching: false, items: [], errorMessage: action.message })

    default:
      return state
  }
}

export default places
