const lightenDarkenColor = (color, diff) => {
  let hexa = false

  if (color[0] === '#') {
    color = color.slice(1)
    hexa = true
  }

  let num = parseInt(color, 16)
  let colors = {
    red: (num >> 16) + diff,
    blue: ((num >> 8) & 0x00FF) + diff,
    green: (num & 0x0000FF) + diff
  }

  Object.keys(colors).forEach((key) => {
    let color = colors[key]
    if (color > 255) color = 255
    if (color < 0) color = 0

    colors[key] = color
  })

  return (hexa ? '#' : '') + (colors.green | (colors.blue << 8) | (colors.red << 16)).toString(16)
}

export const darkenColor = (color, diff) => {
  return lightenDarkenColor(color, -diff)
}

export const lightenColor = (color, diff) => {
  return lightenDarkenColor(color, diff)
}
