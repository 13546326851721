import { callApi } from '../middleware/api'

export const FOOD_ITEMS_REQUEST = 'FOOD_ITEMS_REQUEST'
export const FOOD_ITEMS_SUCCESS = 'FOOD_ITEMS_SUCCESS'
export const FOOD_ITEMS_FAILURE = 'FOOD_ITEMS_FAILURE'

const requestFoodItems = () => {
  return { type: FOOD_ITEMS_REQUEST, isFetching: true }
}

export const receiveFoodItems = (response) => {
  return { type: FOOD_ITEMS_SUCCESS, items: response }
}

const foodItemsError = (message) => {
  return { type: FOOD_ITEMS_FAILURE, message }
}

export const fetchFoodItems = (id, filter = "%", low = 0, high = 100) => {
  return dispatch => {
    dispatch(requestFoodItems())
    return callApi(`food_items?name=${filter}&place_id=${id}&expand=i18ns&expand=prices&expand=tags&low=${low}&high=${high}`)
      .then(json => dispatch(receiveFoodItems(json)))
      .catch(err => {
        dispatch(foodItemsError(err.message))
        throw err
      })
  }
}

export const FOOD_ITEM_REQUEST = 'FOOD_ITEM_REQUEST'
export const FOOD_ITEM_SUCCESS = 'FOOD_ITEM_SUCCESS'
export const FOOD_ITEM_FAILURE = 'FOOD_ITEM_FAILURE'

const requestFoodItem = () => {
  return { type: FOOD_ITEM_REQUEST, isFetching: true }
}

export const receiveFoodItem = (response) => {
  return { type: FOOD_ITEM_SUCCESS, item: response }
}

const foodItemError = (message) => {
  return { type: FOOD_ITEM_FAILURE, message }
}

export const fetchFoodItem = (id) => {
  return dispatch => {
    dispatch(requestFoodItem())
    return callApi(`food_items/${id}?expand=all&omit=children&omit=food_items&omit=carte_foods`)
      .then(json => dispatch(receiveFoodItem(json)))
      .catch(err => {
        dispatch(foodItemError(err.message))
        throw err
      })
  }
}


export const UPDATE_FOOD_ITEM = 'UPDATE_FOOD_ITEM'
export const UPDATED_FOOD_ITEM = 'UPDATED_FOOD_ITEM'
export const CREATE_FOOD_ITEM = 'CREATE_FOOD_ITEM'
export const CREATED_FOOD_ITEM = 'CREATED_FOOD_ITEM'

const updateFoodItem = () => {
  return { type: 'UPDATE_FOOD_ITEM' }
}

const foodItemUpdated = (response) => {
  return { type: 'UPDATED_FOOD_ITEM', item: response }
}

const createFoodItem = () => {
  return { type: 'CREATE_FOOD_ITEM' }
}

const foodItemCreated = (response) => {
  return { type: 'CREATED_FOOD_ITEM', item: response }
}

export const modifyFoodItem = (data) => {
  return dispatch => {
    dispatch(updateFoodItem())
    return callApi(`/food_items/${data.id}`, 'PUT', data)
      .then(json => dispatch(foodItemUpdated(json)))
      .catch(err => {
        dispatch(foodItemError(err.message))
        throw err
      })
  }
}

export const newFoodItem = (data) => {
  return dispatch => {
    dispatch(createFoodItem())
    return callApi('/food_items', 'POST', data)
      .then(json => dispatch(foodItemCreated(json)))
      .catch(err => {
        dispatch(foodItemError(err.message))
        throw err
      })
  }
}


const deletingFoodItem = () => {
  return { type: 'DELETE_FOOD_ITEM' }
}

const foodItemDeleted = (response) => {
  return { type: 'DELETED_FOOD_ITEM', item: response }
}

export const deleteFoodItem = (data) => {
  return dispatch => {
    dispatch(deletingFoodItem())
    return callApi(`/food_items/${data.id}`, 'DELETE', data)
      .then(json => dispatch(foodItemDeleted(json)))
      .catch(err => {
        dispatch(foodItemError(err.message))
        throw err
      })
  }
}