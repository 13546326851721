import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import styled, { withTheme } from "styled-components"
import { withTranslation } from "react-i18next"

import { vars } from "../../components/variables.js"

import { hasFeature, placeHasFeature } from "../../libs/features"

import { IconHome, IconChef, IconBars, IconStar, IconPrint } from "./Icons"

import LanguageSelector from "../../utils/LanguageSelector"
const ENTRIES = [
  {
    icon: IconHome,
    name: "home",
    to: "/"
  },
  {
    icon: IconChef,
    name: "planning",
    to: "/restaurants/:id"
  },
  {
    icon: IconStar,
    name: "edito_planning",
    to: "/restaurants/:id/edito_planning||/restaurants/:id/edito_item/new||/restaurants/:id/edito_item/:edito_item_id",
    requires: ['display']
  },
  {
    icon: IconBars,
    name: "analytics",
    to: "/restaurants/:id/fluidities"
  },
  {
    icon: IconPrint,
    name: "print",
    to: "/print/:id"
  }
]

const Wrapper = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 70px;
  background: ${props =>
    props.theme && props.theme.header ? props.theme.header.background : "#FFF"};
  box-shadow: ${props =>
    props.theme && props.theme.header ? "none" : `0 2px 30px ${vars['light-grey-blue']}`};
  
  ul {
    margin-top: 70px;
    padding-top: 60px;
    height: 100vh;
    background: ${props =>
      props.theme && props.theme.aside ? props.theme.aside.background : "#FFF"};
  }
  a {
    color: ${props => (props.theme && props.theme.aside ? "#FFF" : "inherit")};
  }
`

const StyledIcon = styled(Link)`
  display: block;
  border-left: 5px solid transparent;

  opacity: ${props => (props.disabled ? "0.5" : "1")};
  pointer-events: ${props => (props.disabled ? "none" : "")};

  &:hover,
  &.active {
    border-color: ${vars["blue"]};

    .svg-icon g {
      stroke: ${vars["blue"]};
    }
    .svg-icon path {
      fill: ${vars["blue"]};
    }
  }

  .svg-icon {
    width: 23px;
    height: 23px;
    margin: 21px;
  }

  .svg-icon g {
    stroke: ${props => (props.live === "true" ? "#FFF" : "rgb(159, 169, 179)")};
  }
  .svg-icon path {
    fill: ${props => (props.live === "true" ? "#FFF" : "rgb(159, 169, 179)")};
  }
`

class Aside extends Component {
  componentDidMount(){
    const { authActions, profileActions } = this.props
    const { history } = this.props
    profileActions.fetchProfile().catch(e => {
      authActions.logoutUser()
      profileActions.clearProfile()
      if (e.message === "409") {
        history.push("/sessionexpired")
      } else {
        // history.push("/login")
        window.location.replace(process.env.REACT_APP_URL_LOGIN_CLIENT);
      } 
    })
  }
  
  getLink(path) {
    const { match } = this.props;
    const {
      restaurants: { items }
    } = this.props


    if (path === "/") {
      return path
    }

    const placeId = match.params.id
    if (placeId) {
      return path.replace(":id", placeId)
    }
    const places = items.filter(place => place.kind === "RESTAURANT")

    if (places.length) {
      //console.log(places)
      for (let place of places) {
        if (placeHasFeature({
          place: place,
          feature: "planning",
        })) {
          return path.replace(":id", place.id)
        }
      }
    }
    return "/"
  }

  hasDisplays() { 
    var displays = JSON.parse(localStorage.getItem('displays')) || []

    if(!displays) return false
    const len = displays.length
    return len > 0 ? true : false
  }

  isActive(path) {
    const { match } = this.props
    return match.path === path
  }

  isActiveFinder(entryTo) {
    let isActive = false;
    if(entryTo.indexOf("||") === -1) {
      isActive = this.isActive(entryTo)
    } else {
      (entryTo.split("||")).forEach(to => {
        if(this.isActive(to)) {
          isActive = true
          return
        }
      })
    }
    return isActive
  }

  render() {
    const { profile, restaurants: { items } } = this.props
    const { theme } = this.props
    const { match } = this.props;
    const hasDisplays = this.hasDisplays()

    return (
      <Wrapper>
        <ul>
          {ENTRIES.map((entry, i) => {
            var enabled = hasFeature({
              profile: profile.user,
              feature: entry.name
            })

            
            if(entry.requires && entry.requires.length){
              if(entry.requires.indexOf('display') > -1 && !hasDisplays) enabled = false
            }

            // if(match.params && match.params.id){
            //   let place = items.filter(place => place.id === parseInt(match.params.id))
            //   if (place.length === 1) {
            //     enabled = !(enabled ^ placeHasFeature({place: place[0], feature: entry.name}))
            //   }
            //} 

            if(!enabled) {
              return
            }
            
            return (
              <li key={`aside-${entry.name}`}>
                <StyledIcon
                  disabled={!enabled}
                  to={enabled ? this.getLink(entry.to.indexOf("||") === -1 ? entry.to : entry.to.split("||")[0]) : "#"}
                  className={this.isActiveFinder(entry.to) ? "active" : ""}
                  live={`${theme.prefix === "live"}`}
                >
                  <entry.icon />
                </StyledIcon>
              </li>
            )
          })}
           <LanguageSelector profileActions={this.props.profileActions} />
        </ul>
       
      </Wrapper>
    )
  }
}

export default withTheme(withTranslation()(withRouter(Aside)))
