// This will be eventually checked against DB

import { get } from "lodash"

const DISABLED = {
    // Eos_Restaurant: ["analytics"]
    Emmanuel_auri: 'print',
    Alexandre_sfr: 'print',
    admin_sfr: 'print',
    admin_hsbc: 'print',
    imokrestaurant: 'print',

}; //this is old school static array / kept for backward compatibility

export const placeHasFeature = ({ place, feature }) => {
  if(!place) return false

  if (place.config) {
    if (!(place.config.isCustom)) return true
  }

  const disabled = [].concat(place.features || [])
  return disabled.includes(feature)
}


export const hasFeature = ({ profile, feature }) => {
  if (!profile || !profile.login) {
    return false
  }

  if (feature === "home") {
    return true
  }

  const disabled = [].concat(profile.features)
  return disabled.includes(feature)
}
