import { callApi } from "../middleware/api"

export const PLACES_REQUEST = "PLACES_REQUEST"
export const PLACES_SUCCESS = "PLACES_SUCCESS"
export const PLACES_FAILURE = "PLACES_FAILURE"

const requestPlaces = () => {
  return { type: PLACES_REQUEST, isFetching: true }
}

export const receivePlaces = response => {
  return { type: PLACES_SUCCESS, items: response }
}

const placesError = message => {
  return { type: PLACES_FAILURE, message }
}

export const fetchPlaces = id => {
  return dispatch => {
    dispatch(requestPlaces())
    return callApi(`places/${id}`)
      .then(json => dispatch(receivePlaces(json)))
      .catch(err => {
        dispatch(placesError(err.message))
        throw err
      })
  }
}

export const fetchChildrenPlaces = parentId => {
  return dispatch => {
    dispatch(requestPlaces())
    return callApi(
      `places?parent_id=${parentId}&kind=CASH&kind=DISTRIBUTION&kind=RESTAURATION&kind=STAND`
    )
      .then(json => dispatch(receivePlaces(json)))
      .catch(err => {
        dispatch(placesError(err.message))
        throw err
      })
  }
}

