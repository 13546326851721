import React, { Component } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  svg {
    stroke: #69717d;
    width: 60px;
    height: 60px;
  }
`

const Spinner = () => {
  return (
    <svg viewBox="0 0 64 64">
      <g strokeWidth="4" strokeLinecap="round">
        <line y1="17" y2="29" transform="translate(32,32) rotate(0)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".45;.35;.25;.15;.1;0;1;.85;.7;.65;.55;.45"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(30)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".55;.45;.35;.25;.15;.1;0;1;.85;.7;.65;.55"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(60)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".65;.55;.45;.35;.25;.15;.1;0;1;.85;.7;.65"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(90)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".7;.65;.55;.45;.35;.25;.15;.1;0;1;.85;.7"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(120)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".85;.7;.65;.55;.45;.35;.25;.15;.1;0;1;.85"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(150)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values="1;.85;.7;.65;.55;.45;.35;.25;.15;.1;0;1"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(180)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values="1;.85;.7;.65;.55;.45;.35;.25;.15;.1;0;1"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(210)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values="0;1;.85;.7;.65;.55;.45;.35;.25;.15;.1;0"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(240)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".1;0;1;.85;.7;.65;.55;.45;.35;.25;.15;.1"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(270)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".15;.1;0;1;.85;.7;.65;.55;.45;.35;.25;.15"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(300)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".25;.15;.1;0;1;.85;.7;.65;.55;.45;.35;.25"
            repeatCount="indefinite"
          />
        </line>
        <line y1="17" y2="29" transform="translate(32,32) rotate(330)">
          <animate
            attributeName="stroke-opacity"
            dur="750ms"
            values=".35;.25;.15;.1;0;1;.85;.7;.65;.55;.45;.35"
            repeatCount="indefinite"
          />
        </line>
      </g>
    </svg>
  )
}

export default class Loading extends Component {

  render() {
    const { style } = this.props
    return (
      <Wrapper style={style}>
        <Spinner />
      </Wrapper>
    )
  }
}
