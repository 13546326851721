import { combineReducers } from "redux"

import auth from "./auth"
import cartes from "./cartes"
import foodGroups from "./food_groups"
//For doggy bag implementation
import doggyBagGroups from "./doggy_bag_groups"
import foodItems from "./food_items"
import editoItems from "./edito_items"
import displayEditoItems from "./display_edito_items"
import items from "./items"
import profile from "./profile"
import restaurants from "./restaurants"
import prints from "./prints"
import displays from "./displays"
import places from "./places"
import tags from "./tags"
import carteFoods from "./carte_foods"
import regions from "./regions"

const appReducer = combineReducers({
  auth,
  cartes,
  foodGroups,
  //For doggy bag implementation
  doggyBagGroups,
  foodItems,
	editoItems,
	displayEditoItems,
  items,
  profile,
	restaurants,
	prints,
	displays,
  places,
  tags,
  carteFoods,
  regions
})

export default appReducer
