import React, { Suspense } from "react"
import { Provider } from "react-redux"
import { I18nextProvider } from "react-i18next"
import "react-dates/initialize"
import { adminStore } from "./store"
import i18n from "./middleware/i18n"
import { AdminContainer } from "./containers"
import "./styles/admin.css"

const store = adminStore()

window.maxTitleLength = 40
window.maxDescLength = 60

function App() {
    return (
        <Suspense fallback="loading">
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <AdminContainer />
                </Provider>
            </I18nextProvider>
        </Suspense>
    )
}
  
export default App;