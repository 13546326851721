import {
  CARTE_FOOD_REQUEST,
  CARTE_FOOD_SUCCESS,
  CARTE_FOOD_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE
} from '../actions/carte_food'

const carteFoods = (state = {isFetched: false, isFetching: false, items: [], current: false}, action) => {
  switch (action.type) {
    case CARTE_FOOD_REQUEST:
    case DELETE_REQUEST:
      return {...state, isFetching: true}

    case CARTE_FOOD_SUCCESS:
      return {...state, isFetched: true, isFetching: false, items: action.items, errorMessage: ''}

    case CARTE_FOOD_FAILURE:
    case DELETE_FAILURE:
      return {...state, isFetching: false, items: [], errorMessage: action.message}

    case DELETE_SUCCESS:
      return {...state, isFetched: true, isFetching: false, current: false, errorMessage: ''}

    default:
      return state
  }
}

export default carteFoods
