import {
  EDITO_ITEMS_REQUEST, EDITO_ITEMS_SUCCESS, EDITO_ITEMS_FAILURE,
  EDITO_ITEM_REQUEST, EDITO_ITEM_SUCCESS, EDITO_ITEM_FAILURE
} from '../actions/edito_item'

const editoItems = (state = { isFetched: false, isFetching: false, items: [], current: false }, action) => {
  switch (action.type) {
    case EDITO_ITEMS_REQUEST:
      return Object.assign({}, state, { isFetching: true })

    case EDITO_ITEMS_SUCCESS:
      return Object.assign({}, state, { isFetched: true, isFetching: false, items: action.items, errorMessage: '' })

    case EDITO_ITEMS_FAILURE:
    case EDITO_ITEM_FAILURE:
      return Object.assign({}, state, { isFetching: false, items: [], errorMessage: action.message })

    case EDITO_ITEM_REQUEST:
      return Object.assign({}, state, { isFetching: true })

    case EDITO_ITEM_SUCCESS:
      return Object.assign({}, state, { isFetched: true, isFetching: false, current: action.item, errorMessage: '' })

    default:
      return state
  }
}

export default editoItems
