/* eslint-disable */
import i18n from "i18next"
import languages from "../locales"
import LanguageDetector from "i18next-browser-languagedetector"
import moment from "moment"

i18n.use(LanguageDetector).init({
  useLocalStorage: process.env.NODE_ENV === "production",
  lng: (localStorage.getItem("language")),
  fallbackLng: 'fr',
  ns: ["common"],
  defaultNS: "common",
  debug: process.env.NODE_ENV === "developement",
  interpolation: {
    escapeValue: false // not needed for react!!
  }
})

Object.keys(languages).map(lang => {
  i18n.addResourceBundle(lang, "common", languages[lang], true)
})

i18n.on("languageChanged", function(lng) {
  moment.locale(lng)
  moment.weekdays(true)
})


export default i18n
