import { callApi } from "../middleware/api"

export const PRINTS_REQUEST = "PRINTS_REQUEST"
export const PRINTS_SUCCESS = "PRINTS_SUCCESS"
export const PRINTS_FAILURE = "PRINTS_FAILURE"

const requestPrints = () => {
  return { type: PRINTS_REQUEST, isFetching: true }
}

export const receivePrints = response => {
  return { type: PRINTS_SUCCESS, items: response }
}

const printsError = message => {
  return { type: PRINTS_FAILURE, message }
}

export const fetchPrints = id => {
  return dispatch => {
    dispatch(requestPrints())
    return callApi("/places?kind=PRINT")
      .then(json => dispatch(receivePrints(json)))
      .catch(err => {
        dispatch(printsError(err.message))
        throw err
      })
  }
}
