import {
  FOOD_GROUPS_REQUEST, FOOD_GROUPS_SUCCESS, FOOD_GROUPS_FAILURE
} from '../actions/food_group'

const foodGroups = (state = { isFetched: false, isFetching: false, items: [] }, action) => {
  switch (action.type) {
    case FOOD_GROUPS_REQUEST:
      return Object.assign({}, state, { isFetching: true })

    case FOOD_GROUPS_SUCCESS:
      return Object.assign({}, state, { isFetched: true, isFetching: false, items: action.items, errorMessage: '' })

    case FOOD_GROUPS_FAILURE:
      return Object.assign({}, state, { isFetching: false, items: [], errorMessage: action.message })

    default:
      return state
  }
}

export default foodGroups
