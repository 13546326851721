import { callApi } from '../middleware/api'

const requestItems = (model) => {
  return { type: 'REQUEST_ITEMS', model }
}

const receiveItems = (model, response) => {
  return { type: 'RECEIVE_ITEMS', model, collection: response, receivedAt: Date.now() }
}

const requestItem = (model) => {
  return { type: 'RECEIVE_ITEM', model }
}

const receiveItem = (model, response) => {
  return { type: 'RECEIVE_ITEM', model, item: response, receivedAt: Date.now() }
}

const updateItem = (model) => {
  return { type: 'UPDATE_ITEM', model }
}

const itemUpdated = (model, response) => {
  return { type: 'UPDATED_ITEM', model, item: response }
}

const createItem = (model) => {
  return { type: 'CREATE_ITEM', model }
}

const itemCreated = (model, response) => {
  return { type: 'CREATED_ITEM', model, item: response }
}

const itemError = (model, message) => {
  return { type: 'FAILURE_ITEM', model, message }
}

export const fetchList = (model) => {
  return dispatch => {
    dispatch(requestItems(model))
    return callApi(model, 'get')
      .then(json => dispatch(receiveItems(model, json)))
      .catch(err => {
        if (!err) return
        dispatch(itemError(model, err.errors))
        throw err
      })
  }
}

export const fetchItem = (model, id) => {
  return dispatch => {
    dispatch(requestItem())
    return callApi(`/${model}/${id}`, 'get')
      .then(json => dispatch(receiveItem(model, json)))
      .catch(err => {
        if (!err) return
        dispatch(itemError(model, err.errors))
        throw err
      })
  }
}

export const modifyItem = (model, id, data) => {
  return dispatch => {
    dispatch(updateItem(model))
    return callApi(`/${model}/${id}`, 'PUT', data)
      .then(json => dispatch(itemUpdated(model, json)))
      .catch(err => {
        if (!err) return
        dispatch(itemError(model, err.errors))
        throw err
      })
  }
}

export const newItem = (model, data) => {
  return dispatch => {
    dispatch(createItem(model))
    return callApi(model, 'POST', data)
      .then(json => dispatch(itemCreated(model, json)))
      .catch(err => {
        if (!err) return
        dispatch(itemError(model, err.errors))
        throw err
      })
  }
}
