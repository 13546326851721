import { callApi } from "../middleware/api"

export const REGIONS_REQUEST = "REGIONS_REQUEST"
export const REGIONS_SUCCESS = "REGIONS_SUCCESS"
export const REGIONS_FAILURE = "REGIONS_FAILURE"

const requestRegions = () => {
  return { type: REGIONS_REQUEST, isFetching: true }
}

export const receiveRegions = response => {
  return { type: REGIONS_SUCCESS, items: response }
}

const regionsError = message => {
  return { type: REGIONS_FAILURE, message }
}



export const fetchRegionPlaces = placeId => {
  return dispatch => {
    dispatch(requestRegions())
    return callApi(
      `regions?place_id=${placeId}`
    )
      .then(json => dispatch(receiveRegions(json)))
      .catch(err => {
        dispatch(regionsError(err.message))
        throw err
      })
  }
}