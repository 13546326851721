import {
  DISPLAYS_REQUEST, DISPLAYS_SUCCESS, DISPLAYS_FAILURE
} from '../actions/display'

const displays = (state = { isFetched: false, isFetching: false, items: [] }, action) => {
  switch (action.type) {
    case DISPLAYS_REQUEST:
      return Object.assign({}, state, { isFetching: true })

    case DISPLAYS_SUCCESS:
      return Object.assign({}, state, { isFetched: true, isFetching: false, items: action.items, errorMessage: '' })

    case DISPLAYS_FAILURE:
      return Object.assign({}, state, { isFetching: false, items: [], errorMessage: action.message })

    default:
      return state
  }
}

export default displays
