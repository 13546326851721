import React, { Component } from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Router, Route, Switch } from "react-router-dom"
import { createBrowserHistory } from "history"
import { ThemeProvider } from "styled-components"

import * as AuthActions from "../actions/auth"
import * as CartesActions from "../actions/carte"
import * as CarteFoodsActions from "../actions/carte_food"
import * as ImagotagActions from "../actions/imagotag"
import * as FoodGroupsActions from "../actions/food_group"
//For doggy bag implementation
import * as DoggyBagGroupsActions from "../actions/doggy_bag_group"
import * as FoodItemsActions from "../actions/food_item"
import * as EditoItemsActions from "../actions/edito_item"
import * as DisplayEditoItemsActions from "../actions/display_edito_item"
import * as ItemsActions from "../actions/item"
import * as ProfileActions from "../actions/profile"
import * as RestaurantsActions from "../actions/restaurant"
import * as PrintsActions from "../actions/print"
import * as DisplaysActions from "../actions/display"
import * as PlacesActions from "../actions/place"
import * as TagsActions from "../actions/tag"
import * as RegionsActions from "../actions/region"
import * as EasilysActions from "../actions/easilys"

import { PrivateLayout, PublicLayout, PrintLayout } from "../layouts/"
import { DefaultTheme, LiveTheme } from "../themes/"

import WebSocket from "../middleware/ws"

import * as Pages from "../pages/"

import "react-big-calendar/lib/css/react-big-calendar.css"
import { ImagoThumb } from "../components/home/ImagoThumb"

const history = createBrowserHistory()
const ROUTES = [
  { path: "/display", exact: true, component: Pages.Display, layout: PublicLayout },
  { path: "/login", exact: true, component: Pages.SilentLogin, layout: PublicLayout },
  { path: "/resetpassword", exact: true, component: Pages.ResetPassword, layout: PublicLayout },
  { path: "/sessionexpired", exact: true, component: Pages.SessionExpired, layout: PublicLayout },
  { path: "/", exact: true, component: Pages.Home, layout: PrivateLayout },
  { path: "/restaurants/:id/food_items/:food_item_id",  exact: true, component: Pages.FoodItem, layout: PrivateLayout },
  { path: "/restaurants/:id", exact: true, component: Pages.Restaurant, layout: PrivateLayout },
  { path: "/restaurants/:id/fluidities", exact: true, component: Pages.RestaurantFluidities, layout: PrivateLayout },
  { path: "/restaurants/:id/live", exact: true, component: Pages.RestaurantLive, layout: PrivateLayout, theme: LiveTheme },
  { path: "/print/:id", exact: true, component: Pages.Print, layout: PrivateLayout },
  { path: "/print/:id/edit/:placeId/:templateId", exact: true, component: Pages.EditoPrint, layout: PrivateLayout },
  { path: "/print/:id/preview/:placeId/:templateId", exact: true, component: Pages.PrintPreview, layout: PrivateLayout },
  { path: "/printing/:id/preview/:placeId/:templateId", exact: true, component: Pages.PrintingPreview, layout: PrintLayout },
  { path: "/printing/:paths", exact: true, component: Pages.Printing, layout: PrintLayout },
  { path: "/restaurants/:id/edito_item/:edito_item_id", exact: true, component: Pages.EditoItem, layout: PrivateLayout },
  { path: "/restaurants/:id/edito_planning", exact: true, component: Pages.EditoPlanning, layout: PrivateLayout },
  {path: "/imagothumb", exact: true, component: ImagoThumb, layout: ({children}) => <div>{children}</div>},
  { path: "*", exact: false, component: Pages.NoMatch, layout: PublicLayout }
]

class AppContainer extends Component {
  componentDidMount() {
    if (history.location.pathname === "/display" || history.location.pathname === "/forgotpassword" || history.location.pathname === "/resetpassword" || history.location.pathname === "/login") return false
    const { authActions, profileActions } = this.props
    profileActions.fetchProfile().catch(e => {
      authActions.logoutUser()
      profileActions.clearProfile()
      if (e.message === "409") {
        history.push("/sessionexpired")
      } else {
        window.location.replace(process.env.REACT_APP_URL_LOGIN_CLIENT);
      } 
    })
  }

  buildUrl(id) {
    var uri = 'ws:';
  
    if (window.location.protocol === 'https:') {
      uri = 'wss:';
    }
  
    return `${uri}//${window.location.host}/api/ws?id=${id}`
  }
  
  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  render() {
    var that = this
    return (
      <Router history={history}>
        <div>
          <WebSocket
              url={that.buildUrl(that.uuidv4())} 
              reconnect={true}
              onMessage={function(){
                //handle message from the server
              }}
              ref={Websocket => {
                that.refWebSocket = Websocket;
              }}
              />
          <Switch>
            {ROUTES.map(
              ({ path, exact, component: Comp, layout: Layout, theme }) => {
                return (
                  <Route
                    path={path}
                    exact={exact}
                    key={path}
                    render={props => {
                      const layout = (
                        <Layout {...this.props} {...props} WebSocket={that.refWebSocket}>
                          <Comp {...this.props} {...props} WebSocket={that.refWebSocket}/>
                        </Layout>
                      )

                      return (
                        <ThemeProvider theme={theme || DefaultTheme}>
                          {layout}
                        </ThemeProvider>
                      )
                    }}
                  />
                )
              }
            )}
          </Switch>
        </div>
      </Router>
    )
  }
}

AppContainer.propTypes = {
  dispatch: PropTypes.func,
  authActions: PropTypes.object.isRequired,
  cartesActions: PropTypes.object.isRequired,
  carteFoodsActions: PropTypes.object.isRequired,
  imagotagActions: PropTypes.object.isRequired,
  foodGroupsActions: PropTypes.object.isRequired,
  doggyBagGroupsActions: PropTypes.object.isRequired,
  foodItemsActions: PropTypes.object.isRequired,
  editoItemsActions: PropTypes.object.isRequired,
  displayEditoItemsActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  profileActions: PropTypes.object.isRequired,
  restaurantsActions: PropTypes.object.isRequired,
  displaysActions: PropTypes.object.isRequired,
  placesActions: PropTypes.object.isRequired,
  tagsActions: PropTypes.object.isRequired,
  regionsActions: PropTypes.object.isRequired,
  easilysActions: PropTypes.object.isRequired,
}

export function mapStateToProps(state) {
  const {
    auth,
    carteFoods,
    imagotagActions,
    cartes,
    displays,
    editoItems,
    displayEditoItems,
    foodGroups,
    doggyBagGroups,
    foodItems,
    items,
    places,
    profile,
    prints,
    restaurants,
    tags,
    regions,
    easilys
  } = state

  const { errorMessage, isAuthenticated } = auth

  return Object.assign(
    {},
    {
      errorMessage,
      isAuthenticated,
      carteFoods,
      imagotagActions,
      cartes,
      displays,
      editoItems,
      displayEditoItems,
      foodGroups,
      doggyBagGroups,
      foodItems,
      places,
      profile,
      prints,
      restaurants,
      tags,
      regions,
      easilys
    },
    items
  )
}

export function mapDispatchToProps(dispatch) {
  return {
    authActions: bindActionCreators(AuthActions, dispatch),
    cartesActions: bindActionCreators(CartesActions, dispatch),
    carteFoodsActions: bindActionCreators(CarteFoodsActions, dispatch),
    imagotagActions: bindActionCreators(ImagotagActions, dispatch),
    foodGroupsActions: bindActionCreators(FoodGroupsActions, dispatch),
    doggyBagGroupsActions: bindActionCreators(DoggyBagGroupsActions, dispatch),
    foodItemsActions: bindActionCreators(FoodItemsActions, dispatch),
    editoItemsActions: bindActionCreators(EditoItemsActions, dispatch),
    displayEditoItemsActions: bindActionCreators(DisplayEditoItemsActions, dispatch),
    itemsActions: bindActionCreators(ItemsActions, dispatch),
    profileActions: bindActionCreators(ProfileActions, dispatch),
    restaurantsActions: bindActionCreators(RestaurantsActions, dispatch),
    printsActions: bindActionCreators(PrintsActions, dispatch),
    displaysActions: bindActionCreators(DisplaysActions, dispatch),
    placesActions: bindActionCreators(PlacesActions, dispatch),
    tagsActions: bindActionCreators(TagsActions, dispatch),
    regionsActions: bindActionCreators(RegionsActions, dispatch),
    easilysActions: bindActionCreators(EasilysActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppContainer)
