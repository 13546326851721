import { callApi } from '../middleware/api'

export const TAGS_REQUEST = 'TAGS_REQUEST'
export const TAGS_SUCCESS = 'TAGS_SUCCESS'
export const TAGS_FAILURE = 'TAGS_FAILURE'

const requestTags = () => {
  return { type: TAGS_REQUEST, isFetching: true }
}

export const receiveTags = (response) => {
  return { type: TAGS_SUCCESS, items: response }
}

const tagsError = (message) => {
  return { type: TAGS_FAILURE, message }
}

export const fetchTags = () => {
  return dispatch => {
    dispatch(requestTags())
    return callApi('tags')
      .then(json => dispatch(receiveTags(json)))
      .catch(err => {
        dispatch(tagsError(err.message))
        throw err
      })
  }
}

export const fetchNewTags = () => {
  return dispatch => {
    dispatch(requestTags())
    return callApi('getpromotiontags')
      .then(json => dispatch(receiveTags(json)))
      .catch(err => {
        dispatch(tagsError(err.message))
        throw err
      })
  }
}

export const fetchEosTags = () => {
  return dispatch => {
    dispatch(requestTags())
    return callApi('geteospromotions')
      .then(json => dispatch(receiveTags(json)))
      .catch(err => {
        dispatch(tagsError(err.message))
        throw err
      })
  }
}