import { callApi } from "../middleware/api"

export const RESTAURANTS_REQUEST = "RESTAURANTS_REQUEST"
export const RESTAURANTS_SUCCESS = "RESTAURANTS_SUCCESS"
export const RESTAURANTS_FAILURE = "RESTAURANTS_FAILURE"

const requestRestaurants = () => {
  return { type: RESTAURANTS_REQUEST, isFetching: true }
}

export const receiveRestaurants = response => {
  return { type: RESTAURANTS_SUCCESS, items: response }
}

const restaurantsError = message => {
  return { type: RESTAURANTS_FAILURE, message }
}

export const fetchRestaurants = () => {
  return dispatch => {
    dispatch(requestRestaurants())
    return callApi("/places?kind=RESTAURANT")
      .then(json => dispatch(receiveRestaurants(json)))
      .catch(err => {
        dispatch(restaurantsError(err.message))
        throw err
      })
  }
}

export const fetchSitesAndRestaurants = () => {
  return dispatch => {
    dispatch(requestRestaurants())
    return callApi("/places?kind=SITE&kind=RESTAURANT")
      .then(json => dispatch(receiveRestaurants(json)))
      .catch(err => {
        dispatch(restaurantsError(err.message))
        throw err
      })
  }
}

// export const fetchDisplaysByRestaurants = () => {
//   return dispatch => {
//     dispatch(requestRestaurants())
//     return callApi("/getcurrentuser?kind=RESTAURANT")
//       .then(json => dispatch(receiveRestaurants(json)))
//       .catch(err => {
//         dispatch(restaurantsError(err.errors))
//         throw err.errors
//       })
//   }
// }
