import {
  CARTES_REQUEST, CARTES_SUCCESS, CARTES_FAILURE
} from '../actions/carte'

const cartes = (state = { isFetched: false, isFetching: false, items: [] }, action) => {
  switch (action.type) {
    case CARTES_REQUEST:
      return Object.assign({}, state, { isFetching: true })

    case CARTES_SUCCESS:
      return Object.assign({}, state, { isFetched: true, isFetching: false, items: action.items, errorMessage: '' })

    case CARTES_FAILURE:
      return Object.assign({}, state, { isFetching: false, items: [], errorMessage: action.message })

    default:
      return state
  }
}

export default cartes
