import React, { Component } from "react"
import styled from "styled-components"

import "pretty-checkbox/dist/pretty-checkbox.css"

const Label = styled.label`
  :before,
  :after {
    width: 14px !important;
    height: 14px !important;
    border-radius: 3px !important;
    border-color: #8a93a5 !important;
    top: 1px !important;
    left: 2px !important;
  }
`

const Img = styled.img`
  top: -1px !important;
`

const DATA_ON =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAALxJREFUeNpi+v//PwMlmImBQsCCxP5Pol5GEEGxC+hjwJlzVxjyy9oZTpy+iDcMsILTQM1TZ61g+Pv3D8PvP38Ju+D2nQcMN2/fB7NPATVPmbUcrNnPy4nB1tKIsAsmz1zG8OHjZwYne3OGA4dPAzX/ZfAHag4NdCMuDLw9HMAJZM+BE0DN//BqxuoCd2croAH/GNZu2MXg6W7PEOTrjD8xgGzDlpBA4oyMjOQnJAKaqZeQWNCdRPekDBBgACBsdQ8tns7WAAAAAElFTkSuQmCC"

class Checkbox extends Component {
  render() {
    const { label, ...rest } = this.props
    return (
      <div className="pretty p-image p-plain">
        <input type="checkbox" {...rest} />

        <div className="state">
          <Img className="image" src={DATA_ON} />
          <Label>
            <span>{label}</span>
          </Label>
        </div>
      </div>
    )
  }
}

export default Checkbox
