import {
  LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_SUCCESS
} from '../actions/auth'

const auth = (state = { isFetching: false, isAuthenticated: false }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return Object.assign({}, state, { isFetching: true, isAuthenticated: false })

    case LOGIN_SUCCESS:
      return Object.assign({}, state, { isFetching: false, isAuthenticated: true })

    case LOGIN_FAILURE:
      return Object.assign({}, state, { isFetching: false, isAuthenticated: false })

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, { isFetching: true, isAuthenticated: false })

    default:
      return state
  }
}

export default auth
