import React, { Component } from "react";
import MaskedInput from "react-text-mask";
import styled from "styled-components";

import moment from "moment";

const Wrapper = styled.div`
  position: relative;
  height: 46px;

  input {
    width: 72px;
    height: 46px;
    line-height: 1.65;
    float: left;
    display: block;
    padding: 19px;
    margin: 0;
    border: 1px solid #bfc5d0;
    border-radius: 3px 0 0 3px;
    color: #8f95a2;
  }

  input:focus {
    outline: 0;
  }

  .input-spinner {
    float: left;
    position: relative;
    width: 24px;
    height: 46px;
  }

  .spinner-button {
    position: relative;
    cursor: pointer;
    border: 1px solid #bfc5d0;
    width: 24px;
    text-align: center;
    color: #333;
    font-size: 12px;
    line-height: 12px;
    z-index: 1;
    background: transparent;
    padding: 0;
  }

  .spinner-button.spinner-up {
    position: absolute;
    height: 50%;
    top: 0;
    border-radius: 0 3px 0 0;
  }

  .spinner-button.spinner-down {
    position: absolute;
    bottom: 0;
    height: 50%;
    border-radius: 0 0 3px 0;
  }
`;

const FORMAT = "HH:mm";

class TimeInput extends Component {
    input = React.createRef();

    getTimeInfo = () => {
        const { value, onChange } = this.props;
        const el = this.input.current.inputElement;

        el.focus();

        const isHours = el.selectionStart <= 3;
        const date = value || moment();

        return {
            isHours,
            date,
        };
    };

    onHandleIncrement = () => {
        const { isHours, date } = this.getTimeInfo();

        if (isHours) {
            date.add(1, "hours");
        } else {
            date.add(1, "minutes");
        }

        date.isValid() && this.props.onChange(date);
    };

    onHandleDecrement = () => {
        const { isHours, date } = this.getTimeInfo();

        if (isHours) {
            date.subtract(1, "hours");
        } else {
            date.subtract(1, "minutes");
        }

        date.isValid() && this.props.onChange(date);
    };

    onHandleChange = ({ target: { value } }) => {
        const date = moment(value, FORMAT);

        date.isValid() && this.props.onChange(date);
    };

    render() {
        return (
            <Wrapper>
                <MaskedInput
                    {...this.props}
                    mask={[/[0-2]/, /\d/, ":", /\d/, /\d/]}
                    guide
                    placeholderChar="-"
                    ref={this.input}
                    value={this.props.value ? this.props.value.format(FORMAT) : null}
                    onChange={this.onHandleChange}
                />

                <div className="input-spinner">
                    <button
                        className="spinner-button spinner-up"
                        onClick={this.onHandleIncrement}
                    >
                        +
          </button>
                    <button
                        className="spinner-button spinner-down"
                        onClick={this.onHandleDecrement}
                    >
                        -
          </button>
                </div>
            </Wrapper>
        );
    }
}

export default TimeInput;
