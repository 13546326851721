import React, { Component } from "react";
import styled from "styled-components";
import { vars } from "../variables";

import { groupBy } from "lodash";
import { hasFeature, placeHasFeature } from "../../libs/features";
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;
const NavItem = styled.div`
  display: flex;
  select {
    color: #fafafb; 
    width: auto;
  }
`;

const Title = styled.h2`
  margin-bottom: 5px;
  font: 400 24px/36px ${vars["font-major"]};
  color: #f8f8f9;
  text-transform: none;
`;

const Form = styled.form`
  display: flex;
`;

const Option = styled.option`
color: #5b6378;
&:disabled {
  background-color: #aeb0b3;
  color: #5b6378;

}
`;


class PlaceSelect extends Component {

  componentDidMount() {
    try {
      const widthRestaurantSlc = document.getElementById('restaurantSelector').offsetWidth
      this.props.setWidthSelectorTypeHours(widthRestaurantSlc)
    } catch { }
  }
  placeHaveMenu(place) {
    if (
      (
        hasFeature({
          profile: this.props.profile.user,
          feature: "planning",
        }) ^
        placeHasFeature({
          place: place,
          feature: "planning",
        })

      )) {
      return true
    } else {
      return false
    }

  }

  render() {
    const {
      restaurants,
      selected,
      onChangeSite,
      onChangeRestaurant,
    } = this.props;
    const places = groupBy(restaurants, "kind");

    return (
      <Wrapper>
        <Nav>
          <NavItem>
            <Form>
              <select
                name="siteId"
                onChange={onChangeSite}
                value={selected.parent_id}
              >
                {places.SITE.map((place, i) => {
                  return (
                    <Option key={`restaurant-${place.id}`} value={place.id}>
                      {place.name}
                    </Option>
                  );
                })}
              </select>
              <select
                id="restaurantSelector"
                name="restaurantId"
                onChange={onChangeRestaurant}
                value={selected.id}
              >
                {places.RESTAURANT.filter(
                  (placeFilter) => placeFilter.parent_id === selected.parent_id
                ).map((place, i) => {
                  return (
                    <Option key={`place-${place.id}`} value={place.id} disabled={this.props.isMenuSelected ? this.placeHaveMenu(place) : null}
                    >
                      {place.description}
                    </Option>
                  );
                })}
              </select>
            </Form>
          </NavItem>
        </Nav>
      </Wrapper>
    );
  }
}

export default PlaceSelect;
