import React, { Component } from "react"
import { withTranslation } from "react-i18next"

import pluralize from "pluralize"

import JsonForm from "react-jsonschema-form"
import Schema from "../../schemas/schema.gen.json"

import styled from "styled-components"

const Wrapper = styled.section`
  .form-group {
    margin-left: 1rem;
    padding-left: 1rem;
    border-left: solid 1px blue;
  }
`

class Form extends Component {
  FieldTemplate(props, t) {
    const {
      id,
      classNames,
      label,
      help,
      required,
      description,
      rawErrors = [],
      children
    } = props

    return (
      <div className={classNames}>
        <label htmlFor={id}>
          <span>
            {t(label)}
            {required ? "*" : null}
          </span>
          {false && description}
          {children}
          {rawErrors.length > 0 && (
            <ul className="errors">
              {rawErrors.map(error => <li>{error}</li>)}
            </ul>
          )}
          {help}
        </label>
      </div>
    )
  }

  getModelName() {
    const { model } = this.props
    return pluralize.singular(model)
  }

  prepareSchema() {
    const model = this.getModelName()
    // try to remove ref properties from model, to simplify the form
    // model properties with property type array or property $ref should go way
    const modelDef = Schema[model]

    for (var mp in modelDef.properties) {
      if (modelDef.properties[mp].items || modelDef.properties[mp]["$ref"]) {
        delete modelDef.properties[mp]
      }
    }
    return Object.assign({}, modelDef, { definitions: Schema })
  }

  render() {
    const { t, data, handleSubmit } = this.props

    return (
      <Wrapper>
        <JsonForm
          schema={this.prepareSchema()}
          formData={data}
          FieldTemplate={props => this.FieldTemplate(props, t)}
          onSubmit={data => handleSubmit(data)}
        />
      </Wrapper>
    )
  }
}

export default withTranslation()(Form)