import loadable from "@loadable/component"

export const Display = loadable(() => import("./Display.js"))

export const Home = loadable(() => import("./Home.js"))
// export const Login = loadable(() => import("./Login.js"))
export const SilentLogin = loadable(() => import("./SilentLogin.js"))
export const NoMatch = loadable(() => import("./NoMatch.js"))

export const ForgotPassword = loadable(() => import("./ForgotPassword.js"))
export const LinkPasswordSend = loadable(() => import("./LinkPasswordSend.js"))
export const ResetPassword = loadable(() => import("./ResetPassword.js"))
export const SessionExpired = loadable(() => import("./SessionExpired.js"))

export const Restaurant = loadable(() => import("./Restaurant.js"))
export const RestaurantFluidities = loadable(() =>
  import("./RestaurantFluidities.js")
)
export const RestaurantLive = loadable(() => import("./RestaurantLive.js"))

export const EditoItem = loadable(() => import("./EditoItem.js"))
export const EditoPlanning = loadable(() => import("./EditoPlanning.js"))

export const Item = loadable(() => import("./Item.js"))
export const List = loadable(() => import("./List.js"))

export const FoodItem = loadable(() => import("./FoodItem.js"))

export const Print = loadable(() => import("./Print.js"))
export const PrintPreview = loadable(() => import("./PrintPreview.js"))
export const PrintingPreview = loadable(() => import("./PrintingPreview.js"))
export const Printing = loadable(() => import("./Printing.js"))
export const EditoPrint = loadable(() => import("./EditoPrint.js"))
