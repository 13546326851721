import {
  RESTAURANTS_REQUEST, RESTAURANTS_SUCCESS, RESTAURANTS_FAILURE
} from '../actions/restaurant'

const restaurants = (state = { isFetched: false, isFetching: false, items: [] }, action) => {
  switch (action.type) {
    case RESTAURANTS_REQUEST:
      return Object.assign({}, state, { isFetching: true })

    case RESTAURANTS_SUCCESS:
      return Object.assign({}, state, { isFetched: true, isFetching: false, items: action.items, errorMessage: '' })

    case RESTAURANTS_FAILURE:
      return Object.assign({}, state, { isFetching: false, items: [], errorMessage: action.message })

    default:
      return state
  }
}

export default restaurants
