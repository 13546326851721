import { callApi } from "../middleware/api"

export const DOGGY_BAG_GROUPS_REQUEST = "DOGGY_BAG_GROUPS_REQUEST"
export const DOGGY_BAG_GROUPS_SUCCESS = "DOGGY_BAG_GROUPS_SUCCESS"
export const DOGGY_BAG_GROUPS_FAILURE = "DOGGY_BAG_GROUPS_FAILURE"

const requestDoggyBagGroups = () => {
  return { type: DOGGY_BAG_GROUPS_REQUEST, isFetching: true }
}

export const receiveDoggyBagGroups = response => {
  return { type: DOGGY_BAG_GROUPS_SUCCESS, items: response }
}

const doggyBagGroupsError = message => {
  return { type: DOGGY_BAG_GROUPS_FAILURE, message }
}

//READ
export const fetchDoggyBagGroups = (id, kinds = [undefined]) => {
  return dispatch => {
    dispatch(requestDoggyBagGroups())
    const params = kinds
      .filter(kind => !!kind)
      .map(kind => kind && `&kind=${kind}`)
      .join("")
    return callApi(`food_groups?place_id=${id}&${params}`)
      .then(json => dispatch(receiveDoggyBagGroups(json)))
      .catch(err => {
        dispatch(doggyBagGroupsError(err.errors))
        throw err.errors
      })
  }
}

export const DOGGY_BAG_GROUP_REQUEST = "DOGGY_BAG_GROUP_REQUEST"
export const DOGGY_BAG_GROUP_SUCCESS = "DOGGY_BAG_GROUP_SUCCESS"
export const DOGGY_BAG_GROUP_FAILURE = "DOGGY_BAG_GROUP_FAILURE"

const requestDoggyBagGroup = () => {
  return { type: DOGGY_BAG_GROUP_REQUEST, isFetching: true }
}

export const receiveDoggyBagGroup = response => {
  return { type: DOGGY_BAG_GROUP_SUCCESS, items: response }
}

const doggyBagGroupError = message => {
  return { type: DOGGY_BAG_GROUP_FAILURE, message }
}

//CREATE
export const createDoggyBagGroup = (restaurantId, params) => {
  return dispatch => {
    dispatch(requestDoggyBagGroup())
    return callApi(`doggy_bag_group/${restaurantId}`, 'post', params)
      .then(json => dispatch(receiveDoggyBagGroup(json)))
      .catch(err => {
        dispatch(doggyBagGroupError(err.errors))
        throw err.errors
      })
  }
}

//UPDATE
export const updateDoggyBagGroup = (id, params) => {
  return dispatch => {
    dispatch(requestDoggyBagGroup())
    return callApi(`doggy_bag_group/${id}`, 'put', params)
      .then(json => dispatch(receiveDoggyBagGroup(json)))
      .catch(err => {
        dispatch(doggyBagGroupError(err.errors))
        throw err.errors
      })
  }
}

export const DELETE_REQUEST = 'DOGGY_BAG_GROUP_DELETE_REQUEST'
export const DELETE_SUCCESS = 'DOGGY_BAG_GROUP_DELETE_SUCCESS'
export const DELETE_FAILURE = 'DOGGY_BAG_GROUP_DELETE_FAILURE'

const destroyRequestDoggyBagGroup = () => {
  return { type: DELETE_REQUEST}
}

export const deletedDoggyBagGroup = response => {
  return { type: DELETE_SUCCESS, item: response }
}

const deletedDoggyBagGroupError = message => {
  return { type: DELETE_FAILURE, message }
}

//DELETE
export const destroyDoggyBagGroup = (id) => {
  return dispatch => {
    dispatch(destroyRequestDoggyBagGroup())
    return callApi(`doggy_bag_group/${id}`, 'delete')
      // Empty respsonse
      .then(json => dispatch(deletedDoggyBagGroup(json)))
      .catch(err => {
        dispatch(deletedDoggyBagGroupError(err.errors))
        throw err.errors
      })
  }
}