import { callApi } from "../middleware/api"

export const IMAGOTAG_UPDATE = "IMAGOTAG_UPDATE"
export const IMAGOTAG_SUCCESS = "IMAGOTAG_SUCCESS"
export const IMAGOTAG_ERROR = "IMAGOTAG_ERROR"

const updateImagotagRequest = () => {
    return { type: IMAGOTAG_UPDATE, isUpdating: true }
}

const updatedImagotag = response => {
    return { type: IMAGOTAG_SUCCESS, items: response }
}
 
const updateImagotagError = response => {
    return { type: IMAGOTAG_ERROR, items: response }
}

export const updateByFoorGroupID = (foodGroupId) => {
    return dispatch => {
        dispatch(updateImagotagRequest())
        return callApi(`imagotag?food_group_id=${foodGroupId}`, 'get')
          .then(json => dispatch(updatedImagotag(json)))
          .catch(err => {
            dispatch(updateImagotagError(err.message))
            throw err
          })
      }
}



//ALL FOODGROUP
export const IMAGOTAGALLFOODGROUP_UPDATE = "IMAGOTAGALLFOODGROUP_UPDATE"
export const IMAGOTAGALLFOODGROUP_SUCCESS = "IMAGOTAGALLFOODGROUP_SUCCESS"
export const IMAGOTAGALLFOODGROUP_ERROR = "IMAGOTAGALLFOODGROUP_ERROR"

const updateImagotagAllFoodGroupRequest = () => {
    return { type: IMAGOTAGALLFOODGROUP_UPDATE, isUpdating: true }
}

const updatedImagotagAllFoodGroup = response => {
    return { type: IMAGOTAGALLFOODGROUP_SUCCESS, items: response }
}
 
const updateImagotagErrorAllFoodGroup = response => {
    return { type: IMAGOTAGALLFOODGROUP_ERROR, items: response }
}

export const updateByForPlaceID = (placeID) => {
    return dispatch => {
        dispatch(updateImagotagAllFoodGroupRequest())
        return callApi(`imagotagallfoodgroup?place_id=${placeID}`, 'get')
          .then(json => dispatch(updatedImagotagAllFoodGroup(json)))
          .catch(err => {
            dispatch(updateImagotagErrorAllFoodGroup(err.message))
            throw err
          })
      }
}

export const setImagotagStatus = placeID => {
    return dispatch => {
        dispatch(updateImagotagAllFoodGroupRequest())
        return callApi(`/setimagotagstatus?place_id=${placeID}`, 'PUT')
          .then(json => dispatch(updatedImagotagAllFoodGroup(json)))
          .catch(err => {
            dispatch(updateImagotagErrorAllFoodGroup(err.errors))
            throw err.errors
          })
      }
}

export const isImagotagConfigured = placeID => {
    return dispatch => {
        dispatch(updateImagotagAllFoodGroupRequest())
        return callApi(`/isimagotagconfigured?place_id=${placeID}`, 'get')
          .then(json => dispatch(updatedImagotag(json)))
          .catch(err => {
            dispatch(updateImagotagErrorAllFoodGroup(err.errors))
            throw err.errors
          })
      }
}

export const getImagotagStatus = displayID => {
  return dispatch => {
      dispatch(updateImagotagAllFoodGroupRequest())
      return callApi(`/getimagotagstatus?display_id=${displayID}`, 'get')
        .then(json => dispatch(updatedImagotag(json)))
        .catch(err => {
          dispatch(updateImagotagErrorAllFoodGroup(err.errors))
          console.log(err.errors)
        })
    }
}

export const fetchDisplaysByRestaurants = () => {
  return dispatch => {
      dispatch(updateImagotagAllFoodGroupRequest())
      return callApi(`/getcurrentuser?kind=RESTAURANT`)
        .then(json => dispatch(updatedImagotag(json)))
        .catch(err => {
          dispatch(updateImagotagErrorAllFoodGroup(err.errors))
          console.log(err.errors)
        })
    }
}

// export const fetchDisplaysByRestaurants = () => {
//   return dispatch => {
//     dispatch(requestRestaurants())
//     return callApi("/getcurrentuser?kind=RESTAURANT")
//       .then(json => dispatch(receiveRestaurants(json)))
//       .catch(err => {
//         dispatch(restaurantsError(err.errors))
//         throw err.errors
//       })
//   }
// }



