import React, { Fragment } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;

  width: 366px;
  height: 366px;

  border: 1px solid #bfc6d0;
`

const Img = styled.img`
  position: relative;
  width: 100%;
  height: 100%;

  margin-bottom: 30px;
  border-radius: 5px;
  object-fit: cover;

  &[src=""]:before {
    content: "";
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
  }
`
const Rulers = () => (
  <Fragment>
    <div className="rulers">
      <div className="no-border-top no-border-left" />
      <div
        className="no-border-top no-border-left no-border-right"
        style={{ flex: 1 }}
      />
      <div className="no-border-top no-border-right" />
    </div>
    <div className="rulers" style={{ flex: 1 }}>
      <div className="no-border-top no-border-left no-border-bottom" />
      <div className="no-borders" style={{ flex: 1 }} />
      <div className="no-border-top no-border-right no-border-bottom" />
    </div>
    <div className="rulers">
      <div className="no-border-left no-border-bottom" />
      <div
        className="no-border-left no-border-bottom no-border-right"
        style={{ flex: 1 }}
      />
      <div className="no-border-right no-border-bottom" />
    </div>
  </Fragment>
)

const StyledRulers = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;

  > .rulers {
    display: flex;
    flex: 0 0 80px

    > div {
      flex: 0 0 80px
      border: 1px solid #c93935;
    }

    .no-borders {
      border: none;
    }
    .no-border-top {
      border-top: none;
    }
    .no-border-right {
      border-right: none;
    }
    .no-border-bottom {
      border-bottom: none;
    }
    .no-border-left {
      border-left: none;
    }
  }
`

const ImgRuler = ({ src }) => (
  <Wrapper>
    <StyledRulers>
      <Rulers />
    </StyledRulers>
    <Img src={src} />
  </Wrapper>
)

export default ImgRuler
