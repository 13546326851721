import styled from "styled-components"
import {vars} from '../variables'

export const DAY_SIZE = 36

export const DatePicker = styled.div`
  margin: 0 auto;

  .CalendarMonth_caption>div>div>div:first-of-type>select {
    width:100%
    padding-left:unset;
  }

  .CalendarMonth_caption>div>div>div>select {
    color: #8b93a7;
    text-transform: capitalize;
    font-size: 12px;
    padding:0;
    padding-left: 10px;
  }

  .SingleDatePicker {
    margin: 0 auto;
    color: #6b7384;
  }
  .SingleDatePickerInput {
    background: none;
  }
  .SingleDatePicker_picker {
    border-top: 2px solid #438cf7;
    top: 44px !important;
  }
  .DateInput {
    background: none;
  }
  .DateInput_fang {
    display: none;
  }
  .DateInput_input,
  .DateInput_input__focused {
    background: none;
    border-bottom: none;
    font-family: inherit;
    font-size: 14px;
  }
  .DateInput_input__focused {
    color: #5294f6;
  }
  .DayPicker_transitionContainer__horizontal {
    width: ${DAY_SIZE * 7 + 46}px;
  }
  .CalendarMonthGrid__horizontal {
    left: 0;
  }
  .CalendarMonthGrid_month__horizontal {
    width: ${DAY_SIZE * 7 + 46}px;
  }
  .CalendarMonth_caption {
    border-bottom: 1px solid #bfc6d0;
    padding-top: 0;
    padding-bottom: 8px;
    margin-bottom: 47px;

    > div {
      padding-top: 8px;
    }
  }
  .DayPicker__horizontal {
    > div > div {
      width: ${DAY_SIZE * 7 + 46}px !important;
    }
  }
  .DayPicker_weekHeaders__horizontal {
    margin-left: 0;
  }
  .DayPicker_weekHeader {
    padding: 0 !important;
    width: 280px;
    /* margin: 0 auto; */
    left: 9px !important;
    right: 9px;
  }
  .DayPickerNavigation {
    height: 0;
  }
  .DayPickerNavigation_button {
    border: none;
  }
  .DayPickerNavigation_button__horizontalDefault {
    top: 8px;
  }
  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 0;
  }
  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 0;
  }
  .DayPicker_weekHeader_li {
    text-transform: uppercase;
    font-size: 10px;
    width: ${DAY_SIZE + 4}px !important;
  }
  .CalendarMonth {
    padding: 0;
  }
  .CalendarMonth_table {
    width: 280px;
    margin: 0 auto;
  }
  .CalendarDay {
    border: none;
    font-size: 12px;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover,
  .CalendarDay__selected_span,
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: none;
  }
  .CalendarDay__selected,
  .CalendarDay__selected_span {
    justify-content: center;
    align-items: center;
    color: #fff !important;
  }
  .CalendarDay__selected span,
  .CalendarDay__selected_span span {
    display: block;
    line-height: 30px;
    width: 30px;
    background: ${vars['blue']};
    border-radius: 36px;
    height: 30px;
    font-weight: normal;
  }
  .CalendarDay__selected_span span {
    opacity: 0.75;
  }

  .CalendarDay__today span {
    font-weight: bold;
  }
  .DayPicker_weekHeader_li_1:nth-last-child(2),
  .DayPicker_weekHeader_li_1:last-child,
  .CalendarDay__outside,
  .CalendarDay__default_2:nth-last-child(2),
  .CalendarDay__lastDayOfWeek {
    color: #c1c8d2;
  }
`
