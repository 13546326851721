import { callApi } from '../middleware/api'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

const requestLogin = () => {
  return { type: LOGIN_REQUEST, isFetching: true, isAuthenticated: false }
}

const receiveLogin = (json) => {
  return { type: LOGIN_SUCCESS, isFetching: false, isAuthenticated: true, data: json }
}

const loginError = () => {
  return { type: LOGIN_FAILURE, isFetching: false, isAuthenticated: false }
}

export const loginUser = (creds) => {
  localStorage.setItem('displays', null)
  
  return dispatch => {
    dispatch(requestLogin())
    return callApi('/login', 'post', creds)
      .then(json => dispatch(receiveLogin(json)))
      .catch(err => {
        dispatch(loginError(err.message))
        throw err
      })
  }
}

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

const requestLogout = () => {
  return { type: LOGOUT_REQUEST, isFetching: true, isAuthenticated: true }
}

const receiveLogout = () => {
  return { type: LOGOUT_SUCCESS, isFetching: false, isAuthenticated: false }
}

export const logoutUser = () => {
  return dispatch => {
    dispatch(requestLogout())
    return callApi('/aldebaran/logout', 'post')
      .then((json) => dispatch(receiveLogout()))
      .catch(err => {
        dispatch(loginError(err.message))
        throw err
      })
  }
}



export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

const requestForgotPassword = () => {
  return { type: FORGOT_PASSWORD_REQUEST, isFetching: true, isAuthenticated: false }
}

const receiveForgotPassword = () => {
  return { type: FORGOT_PASSWORD_SUCCESS, isFetching: false, isAuthenticated: true }
}

const ForgotPasswordError = () => {
  return { type: FORGOT_PASSWORD_FAILURE, isFetching: false, isAuthenticated: false }
}

export const forgotPassword = (creds) => { 
  return dispatch => {
    dispatch(requestForgotPassword())
    return callApi('/forgot-password', 'post', creds)
      .then(json => dispatch(receiveForgotPassword()))
      .catch(err => {
        dispatch(ForgotPasswordError(err.message))
        throw err
      })
  }
}


export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

const requestChangePassword = () => {
  return { type: CHANGE_PASSWORD_REQUEST, isFetching: true, isAuthenticated: false }
}

const receiveChangePassword = () => {
  return { type: CHANGE_PASSWORD_SUCCESS, isFetching: false, isAuthenticated: true }
}

const ChangePasswordError = () => {
  return { type: CHANGE_PASSWORD_FAILURE, isFetching: false, isAuthenticated: false }
}

export const changePassword = (creds) => { 
  return dispatch => {
    dispatch(requestChangePassword())
    return callApi('/change-password', 'post', creds)
      .then(json => dispatch(receiveChangePassword()))
      .catch(err => {
        dispatch(ChangePasswordError(err.message))
        throw err
      })
  }
}