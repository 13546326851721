import React, { Component } from "react"
import styled from "styled-components"

import { Aside, Header, Loading } from "../components/common/"
import { withTranslation } from "react-i18next"

let currentLocation = ""

const needToScrollTop = url => {
  if (currentLocation !== url) {
    window.scrollTo(0, 0)
    currentLocation = url
  }
}

const Wrapper = styled.section`
  width: 100vw;
  padding: 70px 0 0 70px;
  background: ${props =>
    props.theme && props.theme.main ? props.theme.main.background : "rgb(252, 253, 255)"};
`

const Main = styled.main`
  display: block;
  padding: 35px 60px 40px;
  
  @media (max-width: 768px) {
    padding: 30px 20px;
  }
`

class PrivateLayout extends Component {
  componentDidMount() {
    needToScrollTop(this.props.location.pathname)
  }

  getRestaurant() {
    const { match } = this.props;

    if (/restaurants/.test(match.path)) {
      return match.params
    }

    return null
  }

  getUserName(profile){
    if(!profile.user || !profile.user.name) return 'Inconnu'
    let tmp = profile.user.name.split(' ')
    if(tmp && tmp instanceof Array && tmp.length > 0 ) return tmp[0]
    return profile.user.name
  }

  render() {
    const { children, profile, restaurants, displays, authActions } = this.props
    if (!profile.isFetched) return <Loading />

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        currentRestaurant: this.getRestaurant(),
        WebSocket: this.props.WebSocket,
        profile: profile
      })
    )

    return (
      <Wrapper>
        <Header user={profile.user} authActions={authActions}  restaurants={restaurants}  />
        
        <Aside profile={profile} authActions={authActions} restaurants={restaurants} displays={displays} profileActions={this.props.profileActions}/>

        <Main>{childrenWithProps}</Main>
      </Wrapper>
    )
  }
}

export default withTranslation()(PrivateLayout)
