import fetch from 'isomorphic-fetch'

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response)
  } else {
    return Promise.reject(new Error(response.status))
  }
}

const buildUrl = (path) => {
  path = path.replace(/^\//, '')
  let separator = path.includes('?') ? '&' : '?'
  return `${process.env.REACT_APP_URL_SERVER}/${path}${separator}`
}

const buildHeaders = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
}

export const callApi = (path, method = 'get', data = {}) => {
  let params = {
    method: method,
    headers: buildHeaders(),
    credentials: 'same-origin'
  }

  if (method !== 'get') params.body = JSON.stringify(data)

  return fetch(buildUrl(path), params)
    .then(checkStatus)
    .then(response => {
      if (response.status === 200) return response.json()
    })
}


export const downloadFile = (path) => {
  window.location.assign(buildUrl(path));
  // window.open(buildUrl(path));

}

/**
 * @param {FileInput} fileInput A DOM input[type=file] element.
 * @return {Promise} An object with a "location" key for the uploaded file's public URL.
 */
export const uploadFile = (file) => {
  const body = new window.FormData()
  body.append('file', file)

  const headers = buildHeaders()
  delete headers['Content-Type']
  const params = {
    method: 'post',
    credentials: 'same-origin',
    headers,
    body
  }

  return fetch(buildUrl('/upload'), params)
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error('Error uploading file:', res)
      return Promise.reject(new Error('Error uploading file'))
    })
}
