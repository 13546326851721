import React from 'react';

import './imagoThumb.css'

export const ImagoThumb = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const src = urlParams.get('src');

    return (
        <div className="center-container text-center">
            <img className='imago-thumbnail' src={src} />
        </div>
    )
}