const initialState = {
  shakabob: {
    isFetching: false,
    isFetched: false,
    items: []
  },
  ploptags: {
    isFetching: false,
    isFetched: false,
    items: []
  }
}

const items = (state = initialState, action) => {
  let items = {}
  switch (action.type) {
    case 'REQUEST_ITEM':
    case 'REQUEST_ITEMS':
      items = Object.assign({}, state[action.model], { isFetching: true })
      return Object.assign({}, state, { [action.model]: items })

    case 'RECEIVE_ITEMS':
      items = Object.assign({}, state[action.model], { isFetching: false, isFetched: true, items: action.collection })
      return Object.assign({}, state, { [action.model]: items })

    case 'RECEIVE_ITEM':
      let collection = []
      if (state.items.length) {
        collection = state.items.map(item =>
          item.id === action.item.id ? Object.assign({}, item, action.item) : item
        )
      } else {
        collection.push(action.item)
      }
      items = Object.assign({}, state[action.model], { isFetching: false, isFetched: true, items: collection })
      return Object.assign({}, state, { [action.model]: items })

    case 'UPDATED_ITEM':
    case 'CREATED_ITEM':
      items = Object.assign({}, state[action.model], { isFetched: true, isFetching: false, item: action.item, errorMessage: '' })
      return Object.assign({}, state, { [action.model]: items })

    case 'FAILURE_ITEM':
      items = Object.assign({}, state[action.model], { isFetching: false, items: {}, errorMessage: action.message })
      return Object.assign({}, state, { [action.model]: items })

    default:
      return state
  }
}

export default items
