import { createStore, applyMiddleware, compose } from "redux"
import thunkMiddleware from "redux-thunk"

import appReducer from "../reducers"

let middlewares = [thunkMiddleware]
if (process.env.NODE_ENV !== "production") {
  // middlewares.push(createLogger())
}
let middleWare = applyMiddleware(...middlewares)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export default function configureStore(initialState = {}) {
  return createStore(appReducer, initialState, composeEnhancers(middleWare))
}
