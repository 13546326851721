import React from "react"

const SVGIcon = ({ children, style, ...rest }) => (
  <svg className="svg-icon" viewBox="0 0 20 20" style={style} {...rest}>
    {children}
  </svg>
)

export const IconHome = props => (
  <SVGIcon {...props}>
    <path d="m16.357875,17.012238c0,0.576688 -0.474723,0.823242 -1.059663,0.823242l-1.059663,0c-0.584941,0 -1.059663,-0.246555 -1.059663,-0.823242l0,-1.044724c0,-1.15442 -0.9484,-2.310929 -2.119264,-2.310929l-2.119295,0c-1.170906,0 -2.119295,1.156509 -2.119295,2.310929l0,1.044724c0,0.576688 -0.474723,0.823242 -1.059642,0.823242l-1.059653,0c-0.58493,0 -1.059653,-0.246555 -1.059653,-0.823242l0,-8.423619c0,-0.138948 0.056164,-0.271628 0.154713,-0.369832l5.443419,-5.365702c0.414317,-0.408487 1.085071,-0.408487 1.498343,0l5.464595,5.386596c0.098517,0.098204 0.154724,0.230884 0.154724,0.368788l0,8.403769zm2.119327,-8.971012c0,-0.276852 -0.111263,-0.541167 -0.308402,-0.737575l-6.674709,-6.613102c-0.826523,-0.818019 -2.169097,-0.821153 -2.998807,-0.006268l-6.66095,6.545195c-0.199218,0.196408 -0.311537,0.461768 -0.311537,0.739664l0,10.087822c0,1.15442 0.94839,1.867966 2.119295,1.867966l3.178948,0c1.170906,0 2.119295,-0.713546 2.119295,-1.867966l0,-1.044724c0,-0.576688 0.474723,-1.044724 1.059653,-1.044724c0.58492,0 1.059642,0.468036 1.059642,1.044724l0,1.044724c0,1.15442 0.948432,1.867966 2.119254,1.867966l3.17899,0c1.170926,0 2.119327,-0.713546 2.119327,-1.867966l0,-10.015736z" />
  </SVGIcon>
)

export const IconChef = props => (
  <SVGIcon {...props}>
    <path d="m15.06935,11l-0.0233,0c-1.0914,0 -2.0033,0.985 -2.0033,2.061l0,0.939l-6.08566,0l0,-0.91c0,-1.039 -0.84389,-1.952 -1.89672,-1.905c-1.39667,0.061 -2.64018,-0.809 -3.02866,-2.05702c-0.99501,-3.199 3.12198,-5.353 5.23169,-2.842c-1.75066,-3.601 3.81775,-6.152 5.49945,-2.526c0.3733,0.804 0.3824,1.687 -0.0254,2.526c1.1249,-1.34 3.7519,-1.587 4.7337,-0.144c1.631,2.396 -0.0903,4.85802 -2.4018,4.85802zm-2.0264,6c0,0.552 -0.4534,1 -1.0143,1l-4.05712,0c-0.55988,0 -1.01428,-0.448 -1.01428,-1l0,-1l6.0857,0l0,1zm1.6836,-13.778c-0.7292,-1.881 -2.5631,-3.222 -4.7265,-3.222c-2.1625,0 -3.99633,1.341 -4.7256,3.222c-3.09053,-0.209 -5.4173,2.196 -5.4173,4.965c0,2.472 1.81861,4.524 4.20929,4.928c0.49294,0.084 0.86214,0.49 0.86214,0.984l0,3.901c0,1.105 0.9088,2 2.02857,2l6.0857,0c1.1208,0 2.0286,-0.895 2.0286,-2l0,-3.901c0,-0.494 0.3692,-0.9 0.8631,-0.984c2.3897,-0.404 4.2083,-2.456 4.2083,-4.928c0,-2.764 -2.3217,-5.175 -5.4163,-4.965z" />
  </SVGIcon>
)

export const IconBars = props => (
  <SVGIcon {...props}>
    <path d="m0.021107,19.182507l3.991583,0l0,-6.558933l-3.991583,0l0,6.558933zm15.966281,0l3.991505,0l0,-18.365014l-3.991505,0l0,18.365014zm-7.983153,0l3.991583,0l0,-13.117867l-3.991583,0l0,13.117867z" />
  </SVGIcon>
)

export const IconStar = props => (
  <SVGIcon {...props}>
    <path
      transform="scale(2)"
      d="m7.558303,5.138752c-0.50615,0.5191 -0.7374,1.265901 -0.61745,1.998901l0.22205,1.362351l-1.163751,-0.6432c-0.307,-0.1698 -0.65255,-0.25955 -0.99915,-0.25955c-0.347105,0 -0.69268,0.08975 -0.999635,0.25955l-1.163791,0.6432l0.222595,-1.362351c0.119425,-0.733 -0.111805,-1.479801 -0.617975,-1.998901l-0.941195,-0.96483l1.301001,-0.199015c0.6998,-0.10669 1.304056,-0.56833 1.617106,-1.235146l0.581895,-1.239761l0.5819,1.239761c0.31255,0.666815 0.9173,1.128456 1.616601,1.235146l1.301501,0.199015l-0.9417,0.96483zm2.167401,-0.42225c0.537,-0.5455 0.241,-1.496001 -0.5015,-1.608501l-1.887001,-0.2855c-0.295,-0.0445 -0.55,-0.2375 -0.6815,-0.516l-0.844,-1.781001c-0.166,-0.3505 -0.489,-0.5255 -0.8115,-0.5255c-0.323025,0 -0.646025,0.175 -0.812025,0.5255l-0.843505,1.781001c-0.132,0.2785 -0.387,0.4715 -0.681505,0.516l-1.887006,0.2855c-0.742505,0.1125 -1.03901,1.063001 -0.502005,1.608501l1.365506,1.386501c0.213505,0.2165 0.311005,0.5285 0.260505,0.8345l-0.322505,1.958001c-0.1005,0.6095 0.364505,1.104501 0.892005,1.104501c0.139,0 0.283005,-0.0345 0.421505,-0.1105l1.687506,-0.9245c0.132005,-0.072 0.276505,-0.1085 0.421525,-0.1085c0.1445,0 0.289,0.0365 0.421,0.1085l1.688001,0.9245c0.1385,0.076 0.282,0.1105 0.421,0.1105c0.5275,0 0.9925,-0.495 0.892,-1.104501l-0.322,-1.958001c-0.0505,-0.306 0.0465,-0.618 0.26,-0.8345l1.365501,-1.386501z"
    />
  </SVGIcon>
)

export const IconPrint = props => (
  <SVGIcon {...props}>
    <g fillRule="evenodd">
      <g strokeWidth="1.3" fillRule="nonzero">
        <g>
          <path d="m17.84251,3.314789c-0.14538,-0.157622 -0.44325,-0.14664 -0.56941,-0.14664c-0.94907,0 -2.02419,0 -3.22534,0l0,-0.665514c-0.00027,-0.124772 -0.12024,-0.225792 -0.26812,-0.225792l-1.51886,0c-0.09847,-0.003594 -0.19134,0.038673 -0.24184,0.110084c-0.05051,0.071411 -0.05051,0.160438 0,0.23184c0.0505,0.071411 0.14337,0.113687 0.24184,0.110084l1.24973,0l0,1.863949c-0.00055,0.292425 -0.28153,0.529293 -0.62813,0.529527l-5.76577,0c-0.34581,-0.000691 -0.62602,-0.236921 -0.62712,-0.52868l0,-1.863949l1.25124,0c0.14808,0 0.26812,-0.101279 0.26812,-0.226215c0.00138,-0.461356 0.49859,-1.700245 1.99115,-1.700245c1.5529,0 1.85615,1.344385 1.95304,1.610993c0.01933,0.053189 0.01933,0.12396 0.04372,0.146139c0.10108,0.091006 0.15615,0.085278 0.26402,0c0.10787,-0.085278 0.15153,-0.139408 0.12189,-0.283525c-0.12189,-0.592729 -0.82977,-1.895892 -2.3814,-1.895892c-1.49383,0 -2.40586,1.415121 -2.50758,1.895892l-1.27333,0c-0.14788,0 -0.26785,0.10102 -0.26812,0.225792l0,0.665514c-1.12664,0 -2.15501,0 -3.08514,0c-0.23184,0 -0.54042,0.026663 -0.70026,0.191629c-0.15983,0.164974 -0.16684,0.40148 -0.16684,0.542556c0,3.319766 0,8.271174 0,14.854224c0,0.761376 0.70319,0.987591 1.32808,0.987591l13.57233,0c0.65273,0 1.09959,-0.278626 1.09959,-0.962474c0,-6.679301 0,-11.68878 0,-15.028435c0,-0.135849 0,-0.267705 -0.15749,-0.438451zm-0.57419,15.817139c-0.1313,0.114326 -0.35541,0.167722 -0.67234,0.160179l-13.06575,0c-0.43524,-0.025946 -0.70997,-0.121022 -0.82418,-0.285227c-0.11421,-0.164214 -0.17131,-0.378264 -0.17131,-0.642177l-0.01085,-14.77146c-0.00394,-0.118646 0.03152,-0.210076 0.10637,-0.274306c0.07485,-0.064222 0.18094,-0.096337 0.31825,-0.096337l3.42817,0l0,1.117824c0.00083,0.564338 1.09807,0.668719 1.74044,0.669203l3.76576,0c0.64237,-0.000484 1.65899,-0.39208 1.65982,-0.956418l0.1128,-0.830609l3.23667,0c0.24353,0 0.39824,0.040177 0.46414,0.12053c0.06589,0.080353 0.09858,0.220824 0.09807,0.421413l0.00993,14.663589c0.00063,0.354875 -0.0647,0.589472 -0.19599,0.703798z" />
          <path d="m14.55934,14.711058l0,-10.151263c0,-0.208918 -0.0742,-0.313368 -0.2226,-0.313368c-0.42978,0 -0.40778,0 0.06599,0c-0.081,0.003257 -0.15738,-0.035018 -0.19892,-0.099664c-0.04155,-0.064654 -0.04155,-0.145258 0,-0.209912c0.04154,-0.064645 0.11792,-0.102921 0.19892,-0.099664c-0.48274,0 -0.51146,0 -0.08617,0c0.1543,0 0.49611,0 0.6071,0.174055c0.05089,0.054346 0.07634,0.246062 0.07634,0.575155c0,4.375251 0,7.749397 0,10.122128c0,0.22765 0,0.753911 -0.12613,0.92959c-0.10879,0.15153 -0.34674,0.295043 -0.76632,0.295043c-1.8538,0 -4.55191,0 -8.09432,0c-0.25586,0 -0.54555,-0.101288 -0.79193,-0.40923c-0.24638,-0.307942 -0.2205,-0.806036 -0.22054,-0.815402c0,-4.657644 0,-8.150869 0,-10.479673c0,-0.292001 0.27696,-0.391665 0.44261,-0.391665c0.48497,0 0.71169,0 0.68018,0c0.08099,-0.003257 0.15737,0.035018 0.19892,0.099664c0.04154,0.064654 0.04154,0.145258 0,0.209912c-0.04155,0.064645 -0.11793,0.102921 -0.19892,0.099664c0.13447,0 -0.01503,0 -0.44852,0c-0.0641,0 -0.23166,0.045387 -0.23361,0.232151c-0.02383,2.281492 -0.02383,5.691218 0,10.229179c0,0.006117 -0.0124,0.377599 0.10892,0.532698c0.12132,0.15509 0.26761,0.283474 0.46731,0.283474c3.54044,0 6.23708,0 8.0899,0c0.03198,0 0.39718,-0.00394 0.4318,-0.278454c0.01333,-0.105712 0.01999,-0.283854 0.01999,-0.534417z" />
        </g>
      </g>
    </g>
  </SVGIcon>
)

export const IconEdition = props => (
  <SVGIcon {...props}>
    <g strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-138.000000, -572.000000)">
        <g transform="translate(140.000000, 573.500000)">
          <path
            d="M20,6.00404702 C17.9100372,1.59953673 13.0653039,-0.788903199 8.29925617,0.23561308 C3.53320839,1.26012936 0.0977198445,5.42849732 0.002045725,10.3028352 C-0.0936283945,15.177173 3.17564216,19.477163 7.89781524,20.6879438 C12.6199883,21.8987246 17.3477248,19.4361796 18.5885875,15.8898901"
            strokeWidth="2.3"
            strokeLinecap="round"
          />
          <polygon
            transform="translate(20.000000, 7.000000) rotate(3.000000) translate(-20.000000, -7.000000) "
            points="17 7.29882882 23 4 23 10"
          />
        </g>
      </g>
    </g>
  </SVGIcon>
)

export const IconApercu = props => (
  <SVGIcon {...props}>
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-307.000000, -571.000000)">
        <g transform="translate(307.000000, 571.500000)">
          <path
            d="M23.3016667,1 L23.3016667,21.55 C23.3016667,22.1 22.8426866,22.55 22.281711,22.55 L1.32162232,22.55 C0.760646711,22.55 0.301666667,22.1 0.301666667,21.55 L0.301666667,1 C0.301666667,0.45 0.760646711,0 1.32162232,0 L22.281711,0 C22.8426866,0 23.3016667,0.45 23.3016667,1 Z M21.3016667,20.5 L21.2505914,2 L2.30166667,2 L2.30166667,20.5 L21.3016667,20.5 Z"
            fill="#9196A2"
            fillRule="nonzero"
          />
          <path
            d="M11.0195551,17.3933124 C11.0195551,9.02510125 21.1871353,9.28978881 23.2261812,9.17732917 C25.98628,9.02510125 28.6253956,9.1773238 29.9702419,9.17732917"
            strokeWidth="2"
          />
          <path
            d="M26.4406863,4.66693082 C23.0616878,1.97393091 30.6523471,7.80318876 30.6523471,8.84834102 C30.6523471,9.89349327 24.1940237,15.1990116 27.185954,12.6678259"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </SVGIcon>
)

export const IconLive = props => (
  <SVGIcon {...props}>
    <path d="m13.973825,10.006629c0,2.194184 -1.778999,3.973066 -3.973066,3.973066c-2.194125,0 -3.973066,-1.778882 -3.973066,-3.973066c0,-2.194125 1.77894,-3.973066 3.973066,-3.973066c2.194067,0 3.973066,1.77894 3.973066,3.973066zm-3.973066,7.946131c-4.381298,0 -7.946131,-3.564892 -7.946131,-7.946131c0,-4.381298 3.564833,-7.946131 7.946131,-7.946131c4.38124,0 7.946131,3.564833 7.946131,7.946131c0,4.38124 -3.564892,7.946131 -7.946131,7.946131zm0,-17.878795c-5.48581,0 -9.932664,4.446854 -9.932664,9.932664c0,5.485752 4.446854,9.932664 9.932664,9.932664c5.485752,0 9.932664,-4.446912 9.932664,-9.932664c0,-5.48581 -4.446912,-9.932664 -9.932664,-9.932664z" />
  </SVGIcon>
)

export const IconLeft = props => (
  <SVGIcon {...props}>
    <path d="M8.388,10.049l4.76-4.873c0.303-0.31,0.297-0.804-0.012-1.105c-0.309-0.304-0.803-0.293-1.105,0.012L6.726,9.516c-0.303,0.31-0.296,0.805,0.012,1.105l5.433,5.307c0.152,0.148,0.35,0.223,0.547,0.223c0.203,0,0.406-0.08,0.559-0.236c0.303-0.309,0.295-0.803-0.012-1.104L8.388,10.049z" />
  </SVGIcon>
)

export const IconRight = props => (
  <SVGIcon {...props}>
    <path d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z" />
  </SVGIcon>
)

export const IconTriangleLeft = props => (
  <SVGIcon {...props}>
    <path d="m15.975577,0.040705l-11.951155,9.960289l11.951155,9.958302l0,-19.918591z" />
  </SVGIcon>
)

export const IconTriangleRight = props => (
  <SVGIcon {...props}>
    <path
      transform="rotate(-180 10,10)"
      d="m15.975577,0.040705l-11.951155,9.960289l11.951155,9.958302l0,-19.918591z"
    />
  </SVGIcon>
)

export const IconCancel = props => (
  <SVGIcon {...props}>
    <path d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z" />
  </SVGIcon>
)

export const IconConfirm = props => (
  <SVGIcon {...props}>
    <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" />
  </SVGIcon>
)

export const IconClose = props => (
  <SVGIcon {...props}>
    <path d="m13.078305,6.92283c-0.170386,-0.170386 -0.446411,-0.170386 -0.615661,0l-2.462644,2.461508l-2.461508,-2.461508c-0.170386,-0.170386 -0.445275,-0.170386 -0.615661,0c-0.16925,0.16925 -0.16925,0.445275 0,0.614525l2.461508,2.462644l-2.462644,2.461508c-0.16925,0.170386 -0.16925,0.446411 0,0.615661c0.170386,0.16925 0.445275,0.16925 0.615661,0l2.462644,-2.461508l2.462644,2.461508c0.16925,0.16925 0.445275,0.16925 0.615661,0c0.168114,-0.16925 0.168114,-0.445275 0,-0.615661l-2.462644,-2.461508l2.462644,-2.462644c0.168114,-0.16925 0.168114,-0.445275 0,-0.614525zm-3.078305,-6.932435c-5.528455,0 -10.009604,4.482285 -10.009604,10.009604c0,5.528455 4.481149,10.009604 10.009604,10.009604s10.009604,-4.481149 10.009604,-10.009604c0,-5.528455 -4.481149,-10.009604 -10.009604,-10.009604zm0,19.149104c-5.047967,0 -9.1395,-4.09267 -9.1395,-9.1395c0,-5.047967 4.09267,-9.1395 9.1395,-9.1395c5.046831,0 9.1395,4.091534 9.1395,9.1395c0,5.046831 -4.09267,9.1395 -9.1395,9.1395z" />
  </SVGIcon>
)

export const IconSearch = props => (
  <SVGIcon {...props}>
    <path d="m14.575125,13.24766l4.73314,4.922738c0.40049,0.416653 0.38702,1.080501 -0.028863,1.481119c-0.196012,0.188315 -0.453726,0.292221 -0.725935,0.292221c-0.123405,0 -0.242962,-0.021038 -0.355079,-0.061446c-0.149446,-0.053621 -0.285679,-0.141493 -0.39972,-0.259767l-4.76911,-4.960195c-1.354211,0.95158 -2.937967,1.453025 -4.600345,1.453025c-4.427154,0 -8.029392,-3.602238 -8.029392,-8.029546c0,-4.427475 3.602238,-8.029546 8.029392,-8.029546c4.427462,0 8.0297,3.602071 8.0297,8.029546c0,1.894911 -0.666798,3.715137 -1.883789,5.161851zm-0.211149,-5.161851c0,-3.272456 -2.662319,-5.934827 -5.934763,-5.934827c-3.272456,0 -5.934827,2.66237 -5.934827,5.934827c0,3.27261 2.66237,5.934993 5.934827,5.934993c3.272443,0 5.934763,-2.662383 5.934763,-5.934993z" />
  </SVGIcon>
)

export const IconPlus = props => (
  <SVGIcon {...props}>
    <path d="m10.016494,20.032987c-5.538532,0 -10.016494,-4.477962 -10.016494,-10.016494s4.477962,-10.016494 10.016494,-10.016494s10.016494,4.477962 10.016494,10.016494s-4.477962,10.016494 -10.016494,10.016494zm0,-18.854576c-4.890406,0 -8.838083,3.947677 -8.838083,8.838083s3.947677,8.838083 8.838083,8.838083s8.838083,-3.947677 8.838083,-8.838083s-3.947677,-8.838083 -8.838083,-8.838083z" />
    <line y2="10" x2="14" y1="10" x1="6" strokeWidth="1" />
    <line
      transform="rotate(90 10,10.000000000000002)"
      y2="10"
      x2="14"
      y1="10"
      x1="6"
      strokeWidth="1"
    />
  </SVGIcon>
)

export const IconTrash = props => (
  <SVGIcon {...props}>
    <g transform="translate(-1,88) scale(0.10000000149011612,-0.10000000149011612)">
      <path d="m81.665889,875.511979c-0.648851,-2.027928 -1.297702,-6.083783 -1.297702,-9.013012l0,-5.407807l-27.035463,0l-27.035463,0l0,-11.266265c0,-10.364964 0.432567,-11.266265 5.407093,-11.266265c6.704795,0 6.272227,2.478578 12.111887,-84.496989c2.379121,-38.530627 4.974525,-70.52682 5.623376,-71.428121c1.297702,-2.478578 123.497995,-2.253253 124.795697,0.225325c0.648851,1.126627 2.811688,32.221518 4.541958,69.400193c4.325674,87.200892 4.325674,86.299591 11.030469,86.299591c4.758241,0 5.190809,0.901301 5.190809,11.266265l0,11.266265l-27.035463,0l-27.035463,0l0,5.407807c0,12.392892 -0.432567,12.618217 -30.279719,12.618217c-23.142356,0 -27.900598,-0.675976 -28.982016,-3.605205zm52.773224,-7.66106l0,-6.759759l-23.791207,0l-23.791207,0l0,6.759759l0,6.759759l23.791207,0l23.791207,0l0,-6.759759zm56.233763,-18.026024l0,-6.759759l-80.02497,0l-80.02497,0l0,6.759759l0,6.759759l80.02497,0l80.02497,0l0,-6.759759zm-12.977022,-25.236434c0,-7.886386 -1.513986,-40.107904 -3.244256,-71.653446c-1.73027,-31.545542 -3.244256,-59.260555 -3.244256,-61.288482c0,-3.83053 -4.325674,-4.055855 -59.478019,-4.055855l-59.478019,0l0,6.309108c0,3.37988 -1.946553,32.897494 -4.325674,65.794988c-2.379121,32.897494 -4.325674,63.992386 -4.325674,69.400193l0,9.463663l67.047948,0l67.047948,0l0,-13.970169z" />
      <path d="m66.742314,819.180654c-0.865135,-0.901301 -1.513986,-27.940338 -1.513986,-59.936531l0,-58.133928l5.407093,0l5.407093,0l-0.432567,59.035229c-0.648851,51.374169 -1.081419,59.260555 -3.893107,59.936531c-1.946553,0.225325 -4.10939,0 -4.974525,-0.901301z" />
      <path d="m92.696358,818.955328c-0.865135,-0.675976 -1.513986,-27.715012 -1.513986,-59.711205l0,-58.133928l6.488511,0l6.488511,0l0,56.106c0,30.869567 -0.648851,57.683278 -1.297702,59.711205c-1.297702,3.605205 -7.56993,4.957157 -10.165334,2.027928z" />
      <path d="m118.434119,817.152726c-0.648851,-2.027928 -1.297702,-29.066964 -1.297702,-59.936531l0,-56.106l6.488511,0l6.488511,0l-0.432567,59.260555c-0.648851,58.133928 -0.648851,59.035229 -5.190809,59.711205c-2.811688,0.450651 -5.190809,-0.901301 -6.055944,-2.929229z" />
      <path d="m146.767284,819.180654c-0.865135,-0.901301 -1.513986,-27.940338 -1.513986,-59.936531l0,-58.133928l5.407093,0l5.407093,0l-0.432567,59.035229c-0.648851,51.374169 -1.081419,59.260555 -3.893107,59.936531c-1.946553,0.225325 -4.10939,0 -4.974525,-0.901301z" />
    </g>
  </SVGIcon>
)

export const IconPin = ({ style, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10.025" height="14.771" viewBox="0 0 10.025 14.771" style={style} {...rest}>
    <path id="pinned" d="M14.372,12.928l-4.93-2.846a1.265,1.265,0,1,0-1.265,2.19l4.929,2.846a1.265,1.265,0,0,0,1.266-2.19ZM10.306,9.85l3.834,2.214,1.3-3.629L12.8,6.907ZM7.479,19.174l3.668-4.456L9.5,13.769Zm9.55-12.746-3.286-1.9a.949.949,0,1,0-.949,1.643l3.287,1.9a.949.949,0,1,0,.948-1.643Z" transform="translate(-7.479 -4.403)" fill="#607ff2" />
  </svg>
)

export const IconUnpin = ({ style, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12.243" height="14.766" viewBox="0 0 12.243 14.766" style={style} {...rest}>
    <path id="unpinned" d="M-2398.542-365.6l2.023-5.4,1.643.949-3.665,4.451Zm8.316-1.611-10.376-10.744a.572.572,0,0,1,.014-.809.573.573,0,0,1,.809.014l10.376,10.745a.572.572,0,0,1-.014.809.571.571,0,0,1-.4.161A.57.57,0,0,1-2390.226-367.212Zm-7.62-5.286a1.263,1.263,0,0,1-.491-1.675l3.032,3.14v0Zm6.049.572.147.085a1.255,1.255,0,0,1,.542.625l0,0Zm-.086-.78-1.507-.869-1.845-1.912,2.01-2.376,2.648,1.528-1.3,3.629h0Zm1.941-3.992-3.286-1.9a.944.944,0,0,1-.443-.576.942.942,0,0,1,.1-.72.95.95,0,0,1,.822-.474.957.957,0,0,1,.474.127l3.286,1.9a.952.952,0,0,1,.348,1.3.953.953,0,0,1-.823.474A.949.949,0,0,1-2389.943-376.7Z" transform="translate(2400.763 380.366)" fill="#607ff2" />
  </svg>
)

export default {
  IconHome,
  IconChef,
  IconBars,
  IconStar,
  IconPrint,
  IconEdition,
  IconApercu,
  IconLive,
  IconLeft,
  IconRight,
  IconClose,
  IconTriangleLeft,
  IconTriangleRight,
  IconCancel,
  IconConfirm,
  IconSearch,
  IconPlus,
  IconTrash,
  IconPin,
  IconUnpin
}
