import { callApi } from '../middleware/api'

export const PROFILE_REQUEST = 'PROFILE_REQUEST'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_FAILURE = 'PROFILE_FAILURE'
export const PROFILE_REMOVED = 'PROFILE_REMOVED'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const PROFILE_UPDATED = 'PROFILE_UPDATED'


const requestProfile = () => {
  return { type: PROFILE_REQUEST, isFetching: true }
}

export const receiveProfile = (response) => {
  let user = response.currentuser
  return { type: PROFILE_SUCCESS, user: user }
}

const removeProfile = () => {
  return { type: PROFILE_REMOVED, user: {} }
}

const profileError = (message) => {
  return { type: PROFILE_FAILURE, message }
}

const updateProfile = () => {
  return { type: PROFILE_UPDATE }
}

const profileUpdated = (response) => {
  return { type: PROFILE_UPDATED, user: response }
}

export const fetchProfile = () => {
  return dispatch => {
    dispatch(requestProfile())
    return callApi('/welcome')
      .then(json => dispatch(receiveProfile(json)))
      .catch(err => {
        dispatch(profileError(err.message))
        throw err
      })
  }
}

export const clearProfile = () => {
  return dispatch => {
    document.cookie = 'imokb=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    dispatch(removeProfile())
  }
}

export const setLanguage = data => {
    return dispatch => {
      dispatch(updateProfile())
      return callApi('/setlanguage', 'PUT', data)
      .then(json => dispatch(profileUpdated(json)))
      .catch(err => {
        dispatch(profileError(err.errors))
        throw err.errors
      })
    }
}
