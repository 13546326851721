//For doggy bag implementation

import {
    DOGGY_BAG_GROUPS_REQUEST,
    DOGGY_BAG_GROUPS_SUCCESS,
    DOGGY_BAG_GROUPS_FAILURE,
    DOGGY_BAG_GROUP_REQUEST,
    DOGGY_BAG_GROUP_SUCCESS,
    DOGGY_BAG_GROUP_FAILURE,
} from '../actions/doggy_bag_group'

const doggyBagGroups = (state = { isFetched: false, isFetching: false, items: [], current: false}, action) => {
    switch (action.type) {
      case DOGGY_BAG_GROUPS_REQUEST:
      case DOGGY_BAG_GROUP_REQUEST:
        return Object.assign({}, state, { isFetching: true })
  
      case DOGGY_BAG_GROUPS_SUCCESS:
        return Object.assign({}, state, { isFetched: true, isFetching: false, items: action.items, errorMessage: '' })
  
      case DOGGY_BAG_GROUPS_FAILURE:
      case DOGGY_BAG_GROUP_FAILURE:
        return Object.assign({}, state, { isFetching: false, errorMessage: action.message })

      case DOGGY_BAG_GROUP_SUCCESS:
        return Object.assign({}, state, {isFetched: true, isFetching: false, current: action.item, errorMessage: ''})
  
      default:
        return state
    }
  }
  
  export default doggyBagGroups