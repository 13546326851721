import { callApi } from "../middleware/api"

export const FOOD_GROUPS_REQUEST = "FOOD_GROUPS_REQUEST"
export const FOOD_GROUPS_SUCCESS = "FOOD_GROUPS_SUCCESS"
export const FOOD_GROUPS_FAILURE = "FOOD_GROUPS_FAILURE"

const requestFoodGroups = () => {
  return { type: FOOD_GROUPS_REQUEST, isFetching: true }
}

export const receiveFoodGroups = response => {
  return { type: FOOD_GROUPS_SUCCESS, items: response }
}

const foodGroupsError = message => {
  return { type: FOOD_GROUPS_FAILURE, message }
}

export const fetchFoodGroups = (id, kinds = [undefined]) => {
  return dispatch => {
    dispatch(requestFoodGroups())
    const params = kinds
      .filter(kind => !!kind)
      .map(kind => kind && `&kind=${kind}`)
      .join("")
    return callApi(`food_groups?place_id=${id}&${params}`)
      .then(items => dispatch(receiveFoodGroups(items)))
      .catch(err => {
        dispatch(foodGroupsError(err.message))
        throw err
      })
  }
}
export const editFoodGroupName = (id, name) => {
    return dispatch => {
      return callApi(`editfoodgroupname?id=${id}&name=${name}`, 'POST')
      .catch(err => {
        dispatch(foodGroupsError(err.message))
        throw err
      })
    }
  }

