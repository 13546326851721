import { callApi } from "../middleware/api"


// Verify RESTAURANT is easilys
export const VERIFY_RESTAURANT_IS_EASILYS_REQUEST = 'VERIFY_RESTAURANT_IS_EASILYS_REQUEST'
export const VERIFY_RESTAURANT_IS_EASILYS_SUCCESS = 'VERIFY_RESTAURANT_IS_EASILYS_SUCCESS'
export const VERIFY_RESTAURANT_IS_EASILYS_FAILURE = 'VERIFY_RESTAURANT_IS_EASILYS_FAILURE'

const verifyRestaurantIsEasilysRequest = () => {
  return { type: VERIFY_RESTAURANT_IS_EASILYS_REQUEST, isFetching: true }
}

export const verifyRestaurantIsEasilysSuccess = response => {
  return { type: VERIFY_RESTAURANT_IS_EASILYS_SUCCESS, items: response }
}

const verifyRestaurantIsEasilysError = message => {
  return { type: VERIFY_RESTAURANT_IS_EASILYS_FAILURE, message }
}

export const verifyRestaurantIsEasilys = ({id}) => {
  return dispatch => {
    dispatch(verifyRestaurantIsEasilysRequest())
    return callApi(`verifyplaceiseasilys?place_id=${id}`, 'get')
      // Empty respsonse
      .then(json =>  dispatch(verifyRestaurantIsEasilysSuccess(json)))
      .catch(err => {
        dispatch(verifyRestaurantIsEasilysError(err.message))
        throw err.message
      })
  }
}


// Acquire easilys
export const ACQUIRE_EASILYS_REQUEST = 'ACQUIRE_EASILYS_REQUEST'
export const ACQUIRE_EASILYS_SUCCESS = 'ACQUIRE_EASILYS_SUCCESS'
export const ACQUIRE_EASILYS_FAILURE = 'ACQUIRE_EASILYS_FAILURE'

const acquireEasilysRequest = () => {
  return { type: ACQUIRE_EASILYS_REQUEST, isFetching: true }
}

export const acquireEasilysSuccess = response => {
  return { type: ACQUIRE_EASILYS_SUCCESS, items: response }
}

const acquireEasilysError = message => {
  return { type: ACQUIRE_EASILYS_FAILURE, message }
}

export const acquireEasilys = ({id}) => {
  return dispatch => {
    dispatch(acquireEasilysRequest())
    return callApi(`acquirefromeasilys?place_id=${id}`, 'get')
      // Empty respsonse
      .then(() => dispatch(acquireEasilysSuccess(true)))
      .catch(err => {
        dispatch(acquireEasilysError(err.message))
        throw err.message
      })
  }
}


