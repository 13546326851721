import {
  PROFILE_REQUEST, PROFILE_SUCCESS, PROFILE_FAILURE, PROFILE_REMOVED
} from '../actions/profile'

const profile = (state = { isFetched: false, isFetching: false, user: {} }, action) => {
  switch (action.type) {
    case PROFILE_REQUEST:
      return Object.assign({}, state, { isFetching: true })

    case PROFILE_SUCCESS:
      return Object.assign({}, state, { isFetched: true, isFetching: false, user: action.user, perms: action.perms, errorMessage: '' })

    case PROFILE_FAILURE:
      return Object.assign({}, state, { isFetching: false, user: {}, errorMessage: action.message })

    case PROFILE_REMOVED:
      return Object.assign({}, state, { isFetching: false, user: {} })

    default:
      return state
  }
}

export default profile
