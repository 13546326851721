import { callApi } from "../middleware/api"
import moment from "moment"

// Create
export const DISPLAY_EDITO_ITEMS_REQUEST = "DISPLAY_EDITO_ITEMS_REQUEST"
export const DISPLAY_EDITO_ITEMS_SUCCESS = "DISPLAY_EDITO_ITEMS_SUCCESS"
export const DISPLAY_EDITO_ITEMS_FAILURE = "DISPLAY_EDITO_ITEMS_FAILURE"

const requestDisplayEditoItems = () => {
  return { type: DISPLAY_EDITO_ITEMS_REQUEST, isFetching: true }
}

export const receiveDisplayEditoItems = response => {
  return { type: DISPLAY_EDITO_ITEMS_SUCCESS, items: response }
}

const displayEditoItemsError = message => {
  return { type: DISPLAY_EDITO_ITEMS_FAILURE, message }
}

const getDatesParams = (pubstart, pubend) => {
  let start = pubstart ? moment(pubstart) : moment().startOf("isoMonth"),
    end = pubend
      ? moment(pubend)
      : moment(start.toISOString()).endOf("isoMonth")

  let params = {
    pub_start: start.format("YYYY-MM-DD"),
    pub_end: end.format("YYYY-MM-DD")
  }
  return params
}

export const fetchDisplayEditoItems = scopes => {
  return dispatch => {
    dispatch(requestDisplayEditoItems())

    let params = ["expand=display", "expand=edito_item"]
    scopes = Object.assign(
      {},
      scopes,
      getDatesParams(scopes["pub_start"], scopes["pub_end"])
    )
    for (var scope in scopes) {
      params.push(`${scope}=${scopes[scope]}`)
    }
    return callApi(`display_edito_items?${params.join("&")}`)
      .then(json => dispatch(receiveDisplayEditoItems(json)))
      .catch(err => {
        dispatch(displayEditoItemsError(err.message))
        throw err
      })
  }
}


// Delete
export const DELETE_REQUEST = 'DISPLAY_EDITO_ITEM_DELETE_REQUEST'
export const DELETE_SUCCESS = 'DISPLAY_EDITO_ITEM_DELETE_SUCCESS'
export const DELETE_FAILURE = 'DISPLAY_EDITO_ITEM_DELETE_FAILURE'

const destroyRequestDisplayEditoItem = () => {
  return { type: DELETE_REQUEST, isFetching: true }
}

export const deletedDisplayEditoItem = response => {
  return { type: DELETE_SUCCESS, items: response }
}

const displayEditoItemError = message => {
  return { type: DELETE_FAILURE, message }
}

export const destroyDisplayEditoItem = ({id}) => {
  return dispatch => {
    dispatch(destroyRequestDisplayEditoItem())
    return callApi(`display_edito_items/${id}`, 'delete')
      // Empty respsonse
      .then(() => dispatch(deletedDisplayEditoItem(id)))
      .catch(err => {
        dispatch(displayEditoItemError(err.message))
        throw err
      })
  }
}
