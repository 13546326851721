import {
  FOOD_ITEMS_REQUEST, FOOD_ITEMS_SUCCESS, FOOD_ITEMS_FAILURE,
  FOOD_ITEM_REQUEST, FOOD_ITEM_SUCCESS, FOOD_ITEM_FAILURE
} from '../actions/food_item'


const foodItems = (state = { isFetched: false, isFetching: false, items: [], current: false }, action) => {
  switch (action.type) {
    case FOOD_ITEMS_REQUEST:
      return Object.assign({}, state, { isFetching: true })

    case FOOD_ITEMS_SUCCESS:
      return Object.assign({}, state, { isFetched: true, isFetching: false, items: action.items, errorMessage: '' })

    case FOOD_ITEMS_FAILURE:
    case FOOD_ITEM_FAILURE:
      return Object.assign({}, state, { isFetching: false, items: [], errorMessage: action.message })

    case FOOD_ITEM_REQUEST:
      return Object.assign({}, state, { isFetching: true })

    case FOOD_ITEM_SUCCESS:
      return Object.assign({}, state, { isFetched: true, isFetching: false, current: action.item, errorMessage: '' })

    default:
      return state
  }
}

export default foodItems
