import React, { Component } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import imoklogo from "../images/pictos/imok-logo.png"

let currentLocation = ""

const needToScrollTop = url => {
  if (currentLocation !== url) {
    window.scrollTo(0, 0)
    currentLocation = url
  }
}

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  height: 70px;
  line-height: 70px;
  border-bottom: 1px solid #9299aa;
  box-shadow: 0 0 10px #dedede;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const Picto = styled(Link)`
  display: block;
  margin: 0;
  width: 90px;
  height: 60px;
  background: url(${props => props.picto}) center no-repeat;
    background-size: contain;
`

export default class PublicLayout extends Component {
  componentDidMount() {
    needToScrollTop(this.props.location.pathname)
  }

  render() {
    const { children } = this.props

    return (
      <main>
        <Wrapper>
          <Picto to="/login" picto={imoklogo} />
        </Wrapper>
        {children}
      </main>
    )
  }
}
