import { callApi } from "../middleware/api"

// Create
export const CARTE_FOOD_REQUEST = "CARTE_FOOD_REQUEST"
export const CARTE_FOOD_SUCCESS = "CARTE_FOOD_SUCCESS"
export const CARTE_FOOD_FAILURE = "CARTE_FOOD_FAILURE"

const requestCarteFood = () => {
  return { type: CARTE_FOOD_REQUEST, isFetching: true }
}

export const receiveCarteFoods = response => {
  return { type: CARTE_FOOD_SUCCESS, items: response }
}

const carteFoodsError = message => {
  return { type: CARTE_FOOD_FAILURE, message }
}

export const createCarteFood = (params) => {
  return dispatch => {
    dispatch(requestCarteFood())
    return callApi('carte_foods', 'post', params)
      .then(json => dispatch(receiveCarteFoods(json)))
      .catch(err => {
        dispatch(carteFoodsError(err.message))
        throw err
      })
  }
}

// update
export const updateCarteFood = (id, params) => {
  return dispatch => {
    dispatch(requestCarteFood())
    return callApi(`carte_foods/${id}`, 'put', params)
      .then(json => dispatch(receiveCarteFoods(json)))
      .catch(err => {
        dispatch(carteFoodsError(err.message))
        throw err
      })
  }
}

export const updateCarteFoodsOrders = (params) => {
  return dispatch => {
    dispatch(requestCarteFood())
    return callApi(`carte_foods_order`, 'put', params)
      .then(json => dispatch(receiveCarteFoods(json)))
      .catch(err => {
        dispatch(carteFoodsError(err.message))
        throw err
      })
  }
}

// Delete
export const DELETE_REQUEST = 'CARTE_FOOD_DELETE_REQUEST'
export const DELETE_SUCCESS = 'CARTE_FOOD_DELETE_SUCCESS'
export const DELETE_FAILURE = 'CARTE_FOOD_DELETE_FAILURE'

const destroyRequestCarteFood = () => {
  return { type: DELETE_REQUEST, isFetching: true }
}

export const deletedCarteFood = response => {
  return { type: DELETE_SUCCESS, items: response }
}

const carteFoodError = message => {
  return { type: DELETE_FAILURE, message }
}

export const destroyCarteFood = ({ id }) => {
  return dispatch => {
    dispatch(destroyRequestCarteFood())
    return callApi(`carte_foods/${id}`, 'delete')
      // Empty respsonse
      .then(() => dispatch(deletedCarteFood(id)))
      .catch(err => {
        dispatch(carteFoodError(err.message))
        throw err
      })
  }
}

// Active
export const ACTIVE_CARTE_FOOD_REQUEST = 'ACTIVE_CARTE_FOOD_REQUEST'
export const ACTIVE_CARTE_FOOD_SUCCESS = 'ACTIVE_CARTE_FOOD_SUCCESS'
export const ACTIVE_CARTE_FOOD_FAILURE = 'ACTIVE_CARTE_FOOD_FAILURE'

const activeCarteFoodRequest = () => {
  return { type: ACTIVE_CARTE_FOOD_REQUEST, isFetching: true }
}

export const activeCarteFoodSuccess = response => {
  return { type: ACTIVE_CARTE_FOOD_SUCCESS, items: response }
}

const activeFoodError = message => {
  return { type: ACTIVE_CARTE_FOOD_FAILURE, message }
}

export const activeCarteFood = ({ id, active }) => {
  return dispatch => {
    dispatch(activeCarteFoodRequest())
    return callApi(`activecartefoods?carte_food_id=${id}&active=${active}`, 'get')
      // Empty respsonse
      .then(() => dispatch(activeCarteFoodSuccess(id)))
      .catch(err => {
        dispatch(activeFoodError(err.message))
        throw err
      })
  }
}

export const createPinCarteFood = ({ id }) => {
  return dispatch => {
    dispatch(requestCarteFood())
    return callApi(`carte_foods/${id}/pin`, 'post')
      // Empty respsonse
      .then((json) => dispatch(receiveCarteFoods(json)))
      .catch(err => {
        dispatch(carteFoodError(err.message))
        throw err
      })
  }
}

export const destroyPinCarteFood = ({ id }) => {
  return dispatch => {
    dispatch(destroyRequestCarteFood())
    return callApi(`carte_foods/${id}/pin`, 'delete')
      // Empty respsonse
      .then(() => dispatch(deletedCarteFood(id)))
      .catch(err => {
        dispatch(carteFoodError(err.message))
        throw err
      })
  }
}