import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import styled from "styled-components"

import Avatar from "./Avatar"

import { vars } from '../variables.js'
import { hasFeature, placeHasFeature } from "../../libs/features"

import { IconLive } from "./Icons"

import imoklogo from "../../images/pictos/imok-logo.png"

const Wrapper = styled.header`
  font-family: ${vars['font-major']};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  line-height: 70px;
  z-index: 100;

  box-shadow: ${props =>
    props.theme && props.theme.header ? "none" : `0 2px 30px ${vars['light-grey-blue']}`};
  background: ${props =>
    props.theme && props.theme.header ? props.theme.header.background : "#FFF"};

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    color: ${props => (props.theme && props.theme.header ? "#FFF" : "inherit")};
  }
`
const User = styled(Link)`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 23px;
  border-left: 1px solid #bfc5d1;
  overflow: hidden;
  text-decoration: none;

  img {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    vertical-align: middle;
    border: 2px solid #fff;
    border-radius: 50%;
  }
`

const Picto = styled(Link)`
  display: block;
  margin: 0;
  margin-left: 130px;
  width: 90px;
  height: 60px;
  background: url(${props => props.picto}) center  no-repeat;
  background-size: contain;
`

const UserName = styled.span`
  text-decoration: none;
`

const Actions = styled.article`
  display: flex;
  flex-direction: row;
  color: ${vars['blue']};
`

const Action = styled(Link)`
  display: flex;
  height: 100%;
  padding: 0 43px 0 23px;
  border-left: 1px solid #bfc5d1;
  align-items: center;
  text-decoration: none;
  background: ${props =>
    props.theme && props.theme.header
      ? props.theme.header.buttonBackground
      : "ihnerit"};
`

const Live = styled.span`
  display: flex;
  margin: 0;
  width: 30px;
  height: 100%;
  align-items: center;

  .svg-icon {
    width: 20px;
    height: 20px;
  }

  .svg-icon > path {
    fill: ${props => (props.theme && props.theme.header ? "#FFF" : vars['blue'])};
  }
`

class Header extends Component {

  handleClick(event) {
    if (event) event.preventDefault()

    const { authActions } = this.props
    authActions.logoutUser().then(() => {
      window.location = process.env.REACT_APP_URL_LOGIN_CLIENT
    })
  }


  placeHaveMenu() {
    const { match } = this.props;
    const hasRestaurantId = match.path && match.path.includes("/restaurants/:id")
    const restaurantId = hasRestaurantId ? Number(match.params.id) : null


  }

  render() {
    const { match } = this.props;
    const { t, user } = this.props

    const hasRestaurantId =
      match.path && match.path.includes("/restaurants/:id")
      const restaurantId = hasRestaurantId ? Number(match.params.id) : null
      const placeValue = this.props.restaurants && this.props.restaurants.items ? this.props.restaurants.items.filter(place => place.id === restaurantId) : null
  
    return (
      <Wrapper>
        <Picto to="/" picto={imoklogo} />

        <Actions>
          {(() => {
            var enabledUser = hasFeature({
              profile: user,
              feature: "planning"
            })
            var enabledPlace = placeHasFeature({
                place: placeValue[0],
                feature: "planning",
              })

            if (hasRestaurantId && enabledUser&& enabledPlace) {
              const { path, params } = match
              const isLive = path.includes("/live")
              const route = `/restaurants/${params.id}`

              return (
                <Action to={isLive ? route : `${route}/live`}>
                  <Live>
                    <IconLive />
                  </Live>
                  Live Mode
                </Action>
              )
            }
          })()}
          <User to=""
            onClick={(e) => this.handleClick(e)}>
            <Avatar user={user} />
            <UserName>{t('nav.logout')}</UserName>
          </User>
        </Actions>
      </Wrapper>
    )
  }
}

export default withTranslation()(withRouter(Header))
