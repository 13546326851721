import React, { Component } from 'react'
import PropTypes from 'prop-types'

const COLORS = ['#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e',
  '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50', '#f1c40f',
  '#e67e22', '#e74c3c', '#ecf0f1', '#95a5a6', '#f39c12', '#d35400',
  '#c0392b', '#bdc3c7', '#7f8c8d']
const SIZE = 100

export default class Avatar extends Component {
  letterAvatar () {
    const { user } = this.props
    let size = SIZE

    let nameSplit = String(user.name).toUpperCase().split(' ')

    let initials = ''
    if (nameSplit.length === 1) {
      initials = nameSplit[0] ? nameSplit[0].charAt(0) : '?'
    } else {
      initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0)
    }

    if (window.devicePixelRatio) {
      size = (size * window.devicePixelRatio)
    }

    let charIndex = (initials === '?' ? 72 : initials.charCodeAt(0)) - 64
    let colourIndex = charIndex % 20
    let canvas = document.createElement('canvas')
    canvas.width = size
    canvas.height = size

    let context = canvas.getContext('2d')
    context.fillStyle = COLORS[colourIndex - 1]
    context.fillRect(0, 0, canvas.width, canvas.height)
    context.font = `${Math.round(canvas.width / 2)}px/${canvas.width}px 'Work Sans'`
    context.textAlign = 'center'
    context.fillStyle = '#FFF'
    context.fillText(initials, size / 2, size / 1.5)

    let dataURI = canvas.toDataURL()
    canvas = null

    return dataURI
  }

  render () {
    const { user } = this.props

    let name = user.name
    let avatar = this.letterAvatar()

    return (
      <img src={avatar} alt={name} />
    )
  }
}

Avatar.propTypes = {
  user: PropTypes.object
}
