const theme = {
  prefix: "live",
  header: {
    background: "rgb(252, 253, 255)",
    buttonBackground: "#40cb97",
    borderColor: "#2d364d"
  },
  aside: {
    background: "#5a6378",
    borderColor: "#2d364d"
  },
  main: {
    background: "#444c61"
  }
}

export default theme
