import React, { Component, Fragment } from "react"
import styled from "styled-components"

const Wrapper = styled.span`
  input[type="checkbox"] {
    height: 0;
    width: 0;
    display: none;
  }

  input:checked + label {
    background: ${props => (props.colors ? props.colors[1] : "#4e89fb")};
  }

  input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width:  ${props => (props.size == "BIG" ? "60px" : "40px")};
    height: ${props => (props.size == "BIG" ? "30px" : "20px")};
    display: block;
    border-radius: 100px;
    position: relative;
    margin-bottom: 0;
    background: ${props => (props.colors ? props.colors[0] : "#e8eaee")};
  }

  label:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: ${props => (props.size === "BIG" ? "25px" : "16px")};
    height: ${props => (props.size == "BIG" ? "25px" : "16px")};
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  label:active:after {
    width: 22px;
  }
`

class Toggle extends Component {
  render() {
    const { id, colors, size, ...rest } = this.props

    return (
      <Wrapper colors={colors} size={size}>
        <input id={id} type="checkbox" {...rest} />
        <label htmlFor={id} />
      </Wrapper>
    )
  }
}

export default Toggle
