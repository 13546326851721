import {
  DISPLAY_EDITO_ITEMS_REQUEST, DISPLAY_EDITO_ITEMS_SUCCESS, DISPLAY_EDITO_ITEMS_FAILURE
} from '../actions/display_edito_item'

const displayEditoItems = (state = { isFetched: false, isFetching: false, items: [] }, action) => {
  switch (action.type) {
    case DISPLAY_EDITO_ITEMS_REQUEST:
      return Object.assign({}, state, { isFetching: true })

    case DISPLAY_EDITO_ITEMS_SUCCESS:
      return Object.assign({}, state, { isFetched: true, isFetching: false, items: action.items, errorMessage: '' })

    case DISPLAY_EDITO_ITEMS_FAILURE:
      return Object.assign({}, state, { isFetching: false, items: [], errorMessage: action.message })

    default:
      return state
  }
}

export default displayEditoItems
