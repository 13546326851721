export const vars = {
  "container-width": "1190px",

  "orange": "rgb(253, 131, 13)",

  "moon-grey": "rgb(233, 244, 255)",

  "cool-grey": "rgb(159, 169, 179)",
  "cool-grey-50": "rgb(159, 169, 179, .5)",

  "iris": "rgb(76, 81, 198)",

  "light-grey-blue": "rgba(154, 166, 201, .2)",

  "slate-grey": "rgba(96, 103, 126, .49)",
  red: "#E7433D",
  white: "#FFF",
  "red-dark": "#c30016",
  grey: "rgb(132, 135, 144)",
  green: "#5bbd66",
  blue: "rgb(96, 127, 242)",
  "light-blue": "#cfe7fe",
  "light-blue-20": "rgba(207, 231, 254, .2)",
  background: "rgb(252, 253, 255)",
  "background-title": "#EBECF0",
  "background-content": "#fff",
  "color-title": "#29b787",
  "color-major": "#5B6378;",
  "color-major-lighter": "#9196A3",
  "color-secondary": "#52afe1",
  "color-light": "#939393",
  "border-color": "#ebebeb",

  "font-major": "Roboto",
  "font-secondary": "Helvetica",

  margin: "10px",

  // Mobile
  "mobile-container-width": "90vw",

  gridCol: "90px",
  gridColSmall: "76px",
  gridColTablet: "65px",
  gridMargin: "10px"
}
