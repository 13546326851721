import { callApi, downloadFile } from "../middleware/api"
import moment from "moment"

export const CARTES_REQUEST = "CARTES_REQUEST"
export const CARTES_SUCCESS = "CARTES_SUCCESS"
export const CARTES_FAILURE = "CARTES_FAILURE"

const requestCartes = () => {
  return { type: CARTES_REQUEST, isFetching: true }
}

export const receiveCartes = response => {
  return { type: CARTES_SUCCESS, items: response }
}

const cartesError = message => {
  return { type: CARTES_FAILURE, message }
}

const getDatesParams = dateStart => {
  let from = dateStart ? moment(dateStart) : moment().startOf("isoWeek"),
    to = moment(from.toISOString()).add(6, "days")

  let params = ""
  for (
    let date = moment(from.toISOString());
    date.isSameOrBefore(to);
    date.add(1, "days")
  ) {
    params += `&date=${date.format("YYYY-MM-DD")}`
  }
  return params
}

export const fetchCartes = (id, dateStart) => {
  let params =
    "expand=prices&expand=carte_foods&expand=food_item&expand=food_group"
  params += getDatesParams(dateStart)
  return dispatch => {
    dispatch(requestCartes())
    return callApi(`planning/cartes?place_id=${id}&${params}`)
      .then(json => dispatch(receiveCartes(json)))
      .catch(err => {
        dispatch(cartesError(err.message))
        throw err
      })
  }
}
export const fetchLiveCartes = (id, date) => {
  let params = `expand=carte_foods&expand=food_item&date=${date}`

  return dispatch => {
    dispatch(requestCartes())
    return callApi(`planning/cartes?place_id=${id}&${params}`)
      .then(json => dispatch(receiveCartes(json)))
      .catch(err => {
        dispatch(cartesError(err.message))
        throw err
      })
  }
}

export const fetchPrintCartes = (id, date) => {
  let params = `expand=prices&expand=tags&expand=carte_foods&expand=food_item&date=${date}`

  return dispatch => {
    dispatch(requestCartes())
    return callApi(`planning/cartes?place_id=${id}&${params}`)
      .then(json => dispatch(receiveCartes(json)))
      .catch(err => {
        dispatch(cartesError(err.message))
        throw err
      })
  }
}




//Export menu csv
export const fetchExportCartesCsv = (id, dateStart) => {
  let params = getDatesParams(dateStart)
  downloadFile(`exportmenucsv?place_id=${id}${params}`)
}

