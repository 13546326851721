import {
  PRINTS_REQUEST,
  PRINTS_SUCCESS,
  PRINTS_FAILURE
} from "../actions/print"

const prints = (
  state = { isFetched: false, isFetching: false, items: [] },
  action
) => {
  switch (action.type) {
    case PRINTS_REQUEST:
      return Object.assign({}, state, { isFetching: true })

    case PRINTS_SUCCESS:
      return Object.assign({}, state, {
        isFetched: true,
        isFetching: false,
        items: action.items,
        errorMessage: ""
      })

    case PRINTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        items: [],
        errorMessage: action.message
      })

    default:
      return state
  }
}

export default prints
