import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./languageselector.css";
import flagEng from "../images/flags/eng.png";
import flagFr from "../images/flags/fr.png";
import flagIta from "../images/flags/ita.png";

class LanguageSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageactive: flagIta,
    };
    if(localStorage.getItem("language") === null) {
      localStorage.setItem("language", 'fr')
      this.props.i18n.changeLanguage(localStorage.getItem("language"));
    }
  }

  changeLanguage(language) {
    const { profileActions } = this.props;
    localStorage.setItem("language", language);
    this.props.i18n.changeLanguage(localStorage.getItem("language"));
    const data = {
      language: localStorage.getItem("language"),
    };
    profileActions.setLanguage(data);
  }

  selectActiveFlag() {
    switch (localStorage.getItem("language")) {
      case "it":
        return flagIta;
      case "fr":
        return flagFr;
      case "en":
        return flagEng;
      default:
        return flagEng;
    }
  }

  unselectedFlag(arrLanguages) {
    var Active = arrLanguages
      .map(function (item) {
        return item.lang;
      })
      .indexOf(localStorage.getItem("language"));

    // remove Flag
    arrLanguages.splice(Active, 1);
  }

  render() {
    let arrLanguages = [
      {
        lang: "fr",
        flag: flagFr,
      },
      {
        lang: "it",
        flag: flagIta,
      },
      {
        lang: "en",
        flag: flagEng,
      },
    ];
    this.unselectedFlag(arrLanguages);

    return (
      //Placeholder for testing
      //Implement this component anywhere to test language change

   
        <li className="dropdown">
          <button className="dropbtn">
            <img src={this.selectActiveFlag()} alt="" />
          </button>
          <div className="dropdown-content">
            {arrLanguages.map((lang, key) => {
              return (
                <a key={key} onClick={() => this.changeLanguage(lang.lang)} href="#">
                  <img src={lang.flag} alt="" />
                </a>
              );
            })}
          </div>
        </li>
    
    );
  }
}

export default withTranslation()(LanguageSelector);
