import {
  TAGS_REQUEST, TAGS_SUCCESS, TAGS_FAILURE
} from '../actions/tag'

const tags = (state = { isFetched: false, isFetching: false, items: [] }, action) => {
  switch (action.type) {
    case TAGS_REQUEST:
      return Object.assign({}, state, { isFetching: true })

    case TAGS_SUCCESS:
      return Object.assign({}, state, { isFetched: true, isFetching: false, items: action.items, errorMessage: '' })

    case TAGS_FAILURE:
      return Object.assign({}, state, { isFetching: false, items: [], errorMessage: action.message })

    default:
      return state
  }
}

export default tags
