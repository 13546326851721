import { callApi } from "../middleware/api";

export const EDITO_ITEMS_REQUEST = "EDITO_ITEMS_REQUEST";
export const EDITO_ITEMS_SUCCESS = "EDITO_ITEMS_SUCCESS";
export const EDITO_ITEMS_FAILURE = "EDITO_ITEMS_FAILURE";

const requestEditoItems = () => {
  return { type: EDITO_ITEMS_REQUEST, isFetching: true };
};

export const receiveEditoItems = (response) => {
  return { type: EDITO_ITEMS_SUCCESS, items: response };
};

const editoItemsError = (message) => {
  return { type: EDITO_ITEMS_FAILURE, message };
};

export const fetchEditoItems = (restaurantId) => {
  return (dispatch) => {
    dispatch(requestEditoItems());
    return callApi(`promotions/read?restaurant_id=${restaurantId}`)
      .then((json) => dispatch(receiveEditoItems(json)))
      .catch((err) => {
        dispatch(editoItemsError(err.message));
        throw err;
      });
  };
};

export const EDITO_ITEM_REQUEST = "EDITO_ITEM_REQUEST";
export const EDITO_ITEM_SUCCESS = "EDITO_ITEM_SUCCESS";
export const EDITO_ITEM_FAILURE = "EDITO_ITEM_FAILURE";

const requestEditoItem = () => {
  return { type: EDITO_ITEM_REQUEST, isFetching: true };
};

export const receiveEditoItem = (response) => {
  return { type: EDITO_ITEM_SUCCESS, item: response };
};

const editoItemError = (message) => {
  return { type: EDITO_ITEM_FAILURE, message };
};

export const fetchEditoItem = (id) => {
  return (dispatch) => {
    dispatch(requestEditoItem());
    return callApi(
      `promotion/read?edito_item_id=${id}`
    )
      .then((json) => dispatch(receiveEditoItem(json)))
      .catch((err) => {
        dispatch(editoItemError(err.message));
        throw err;
      });
  };
};

export const UPDATE_EDITO_ITEM = "UPDATE_EDITO_ITEM";
export const UPDATED_EDITO_ITEM = "UPDATED_EDITO_ITEM";
export const CREATE_EDITO_ITEM = "CREATE_EDITO_ITEM";
export const CREATED_EDITO_ITEM = "CREATED_EDITO_ITEM";

const updateEditoItem = () => {
  return { type: "UPDATE_EDITO_ITEM" };
};

const editoItemUpdated = (response) => {
  return { type: "UPDATED_EDITO_ITEM", item: response };
};

const createEditoItem = () => {
  return { type: "CREATE_EDITO_ITEM" };
};

const editoItemCreated = (response) => {
  return { type: "CREATED_EDITO_ITEM", item: response };
};

export const modifyEditoItem = (data) => {
  return (dispatch) => {
    dispatch(updateEditoItem());
    return callApi(`/promotion/update`, "PUT", data)
      .then((json) => dispatch(editoItemUpdated(json)))
      .catch((err) => {
        dispatch(editoItemError(err.message));
        throw err;
      });
  };
};

/* export const newEditoItem = data => {
  return dispatch => {
    dispatch(createEditoItem())
    return callApi("/createpromotion", "POST", data)
      .then(json => dispatch(editoItemCreated(json)))
      .catch(err => {
        dispatch(editoItemError(err.message))
        throw err
      })
  }
} */
export const newEditoItem = (data) => {
  return (dispatch) => {
    dispatch(createEditoItem());
    return callApi("/promotion/create", "POST", data)
      .then((json) => dispatch(editoItemCreated(json)))
      .catch((err) => {
        dispatch(editoItemError(err.message));
        throw err;
      });
  };
};

const deletingEditoItem = () => {
  return { type: "DELETE_EDITO_ITEM" };
};

const editoItemDeleted = (response) => {
  return { type: "DELETED_EDITO_ITEM", item: response };
};

export const deleteEditoItem = (id) => {
  return (dispatch) => {
    dispatch(deletingEditoItem());
    return callApi(`/promotion/delete?edito_item_id=${id}`, "delete")
      .then((json) => dispatch(editoItemDeleted(json)))
      .catch((err) => {
        dispatch(editoItemError(err.message));
        throw err;
      });
  };
};
