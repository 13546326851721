import React, { Component } from "react"
import ReactDOM from "react-dom"
import { CirclePicker } from "react-color"
import styled from "styled-components"

import chroma from "chroma-js"
import { map, flatten, uniq } from "lodash"

import { IconLeft, IconRight } from "./Icons"

const COLORS = uniq(
  flatten(
    map(
      [
        chroma.brewer.Blues,
        chroma.brewer.Greens,
        chroma.brewer.Oranges,
        chroma.brewer.Purples,
        chroma.brewer.Reds
      ],
      scheme => {
        scheme.shift()
        return scheme
      }
    )
  )
)

const Wrapper = styled.section`
  display: flex;
`

const StyledCirclePicker = styled.div`
  .circle-picker {
    overflow: hidden;
    flex-direction: row;
    flex-wrap: nowrap !important;

    margin-right: 0 !important;
    margin-bottom: 0 !important;

    padding: 5px;
    height: 30px;
  }
`

const Button = styled.button`
  flex: 0 0 30px
  border: none;
  background: transparent;
  height: 30px;
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
`

const WIDTH = 306

class ColorPicker extends Component {
  picker = React.createRef()

  scrollLeft = () => {
    const el = ReactDOM.findDOMNode(this.picker.current)
    el.scrollLeft = el.scrollLeft - WIDTH
  }
  scrollRight = () => {
    const el = ReactDOM.findDOMNode(this.picker.current)
    el.scrollLeft = el.scrollLeft + WIDTH
  }
  render() {
    return (
      <Wrapper>
        <Button onClick={this.scrollLeft}><IconLeft /></Button>
        <StyledCirclePicker>
          <CirclePicker
            colors={COLORS}
            ref={this.picker}
            circleSize={18}
            width={WIDTH}
            {...this.props}
          />
        </StyledCirclePicker>
        <Button onClick={this.scrollRight}><IconRight /></Button>
      </Wrapper>
    )
  }
}

export default ColorPicker
