import React from "react"
import styled from "styled-components"
import moment from "moment" // FIXME can't find a datepicker that doesn't depend on this
import 'moment/locale/it'  
import localizationFR from 'moment/locale/fr';
import localizationIT from 'moment/locale/it';
import localizationEN from 'moment/locale/en-gb';
import { IconTriangleLeft, IconTriangleRight } from "./Icons"
import "react-dates/lib/css/_datepicker.css"
import i18next from "i18next";

const IconLeft = styled(IconTriangleLeft)`
  width: 10px;
  height: 10px;
  fill: #bfc5d1;
  margin-left: 10px;
`

const IconRight = styled(IconTriangleRight)`
  width: 10px;
  height: 10px;
  fill: #bfc5d1;
  margin-right: 10px;
`

export const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
  //change calendar language
  if (localStorage.getItem("language") === "en") {
    moment.updateLocale("en", localizationEN);
  }
  else if (localStorage.getItem("language") === "it") {
    moment.updateLocale("it", localizationIT);
  }
  else {
    moment.updateLocale("fr", localizationFR);
  }

  const year = moment().year()

  const t = i18next.getFixedT();

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <select
            value={month.month()}
            onChange={e => {
              onMonthSelect(month, e.target.value)
            }}
          >
            {moment.months().map((label, value) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <select
            value={month.year()}
            onChange={e => {
              onYearSelect(month, e.target.value)
            }}
          >
            <option value={year - 1}>{t("fluidities.last_year")}</option>
            <option value={year}>{year}</option>
            <option value={year + 1}>{t("fluidities.next_year")}</option>
          </select>
        </div>
      </div>
    </div>
  )
}

export const renderDayContents = day => <span>{day.format("D")}</span>

export const NavPrev = () => (
  <span className="DayPickerNavigation_button__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault">
    <IconLeft />
  </span>
)

export const NavNext = () => (
  <span className="DayPickerNavigation_button__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault">
    <IconRight />
  </span>
)

export { DAY_SIZE, DatePicker } from "./DatePicker_styl"
