import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { darkenColor } from "../../libs/color"
import { vars } from "../variables.js"

export default class Button extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
      active: false,
      color: this.props.color || vars["border-color"]
    }
  }

  handleClick(event) {
    const { action, disabled, href, loading, target } = this.props

    event.preventDefault()
    if (disabled || loading) return false

    if (action) return action(event)
    if (target) {
      let newWindow = window.open(href, target)
      newWindow.opener = null
      return newWindow
    }

    return this.props.history.push(href)
  }

  getStyles() {
    const { color } = this.state
    const { hover } = this.props

    let hoverState = hover || this.state.hover
    let darken = darkenColor(color, 20)
    let styles = {
      borderColor: hoverState ? color : darken
    }
    if (this.props.color === color) {
      styles["backgroundColor"] = this.state.active ? color : darken
    }
    return styles
  }

  render() {
    const {
      classnames,
      disabled,
      hover,
      href,
      kind,
      loading,
      target,
      text
    } = this.props

    let classList = classNames(
      "btn",
      {
        hover: hover || this.state.hover,
        active: this.state.active,
        loading: loading,
        disabled: loading === true ? true : disabled
      },
      classnames,
      kind
    )

    return (
      <a
        className={classList}
        style={this.getStyles()}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseOut={() => this.setState({ hover: false, active: false })}
        onMouseDown={() => this.setState({ active: true })}
        onMouseUp={() => this.setState({ active: false })}
        onClick={e => this.handleClick(e)}
        href={href || "#"}
        target={target}
      >
        {text}
        {loading && (
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="loader"
          >
            <rect
              x="0"
              y="0"
              width="100"
              height="100"
              fill="none"
              className="bk"
            />
            <rect
              x="46.5"
              y="40"
              width="7"
              height="20"
              rx="5"
              ry="5"
              fill="#939393"
              transform="rotate(0 50 50) translate(0 -30)"
            >
              <animate
                attributeName="opacity"
                from="1"
                to="0"
                dur="1s"
                begin="0s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="46.5"
              y="40"
              width="7"
              height="20"
              rx="5"
              ry="5"
              fill="#939393"
              transform="rotate(30 50 50) translate(0 -30)"
            >
              <animate
                attributeName="opacity"
                from="1"
                to="0"
                dur="1s"
                begin="0.08333333333333333s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="46.5"
              y="40"
              width="7"
              height="20"
              rx="5"
              ry="5"
              fill="#939393"
              transform="rotate(60 50 50) translate(0 -30)"
            >
              <animate
                attributeName="opacity"
                from="1"
                to="0"
                dur="1s"
                begin="0.16666666666666666s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="46.5"
              y="40"
              width="7"
              height="20"
              rx="5"
              ry="5"
              fill="#939393"
              transform="rotate(90 50 50) translate(0 -30)"
            >
              <animate
                attributeName="opacity"
                from="1"
                to="0"
                dur="1s"
                begin="0.25s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="46.5"
              y="40"
              width="7"
              height="20"
              rx="5"
              ry="5"
              fill="#939393"
              transform="rotate(120 50 50) translate(0 -30)"
            >
              <animate
                attributeName="opacity"
                from="1"
                to="0"
                dur="1s"
                begin="0.3333333333333333s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="46.5"
              y="40"
              width="7"
              height="20"
              rx="5"
              ry="5"
              fill="#939393"
              transform="rotate(150 50 50) translate(0 -30)"
            >
              <animate
                attributeName="opacity"
                from="1"
                to="0"
                dur="1s"
                begin="0.4166666666666667s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="46.5"
              y="40"
              width="7"
              height="20"
              rx="5"
              ry="5"
              fill="#939393"
              transform="rotate(180 50 50) translate(0 -30)"
            >
              <animate
                attributeName="opacity"
                from="1"
                to="0"
                dur="1s"
                begin="0.5s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="46.5"
              y="40"
              width="7"
              height="20"
              rx="5"
              ry="5"
              fill="#939393"
              transform="rotate(210 50 50) translate(0 -30)"
            >
              <animate
                attributeName="opacity"
                from="1"
                to="0"
                dur="1s"
                begin="0.5833333333333334s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="46.5"
              y="40"
              width="7"
              height="20"
              rx="5"
              ry="5"
              fill="#939393"
              transform="rotate(240 50 50) translate(0 -30)"
            >
              <animate
                attributeName="opacity"
                from="1"
                to="0"
                dur="1s"
                begin="0.6666666666666666s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="46.5"
              y="40"
              width="7"
              height="20"
              rx="5"
              ry="5"
              fill="#939393"
              transform="rotate(270 50 50) translate(0 -30)"
            >
              <animate
                attributeName="opacity"
                from="1"
                to="0"
                dur="1s"
                begin="0.75s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="46.5"
              y="40"
              width="7"
              height="20"
              rx="5"
              ry="5"
              fill="#939393"
              transform="rotate(300 50 50) translate(0 -30)"
            >
              <animate
                attributeName="opacity"
                from="1"
                to="0"
                dur="1s"
                begin="0.8333333333333334s"
                repeatCount="indefinite"
              />
            </rect>
            <rect
              x="46.5"
              y="40"
              width="7"
              height="20"
              rx="5"
              ry="5"
              fill="#939393"
              transform="rotate(330 50 50) translate(0 -30)"
            >
              <animate
                attributeName="opacity"
                from="1"
                to="0"
                dur="1s"
                begin="0.9166666666666666s"
                repeatCount="indefinite"
              />
            </rect>
          </svg>
        )}
      </a>
    )
  }
}

Button.propTypes = {
  action: PropTypes.func,
  classnames: PropTypes.string,
  color: PropTypes.string,
  hover: PropTypes.bool,
  href: PropTypes.string,
  text: PropTypes.string.isRequired
}

Button.propTypes = {
  router: PropTypes.shape({
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired
  }).isRequired
}
