import { callApi } from "../middleware/api"

export const DISPLAYS_REQUEST = "DISPLAYS_REQUEST"
export const DISPLAYS_SUCCESS = "DISPLAYS_SUCCESS"
export const DISPLAYS_FAILURE = "DISPLAYS_FAILURE"

const requestDisplays = () => {
  return { type: DISPLAYS_REQUEST, isFetching: true }
}

export const receiveDisplays = response => {
  localStorage.setItem('displays', JSON.stringify(response))
  return { type: DISPLAYS_SUCCESS, items: response }
}

const displaysError = message => {
  return { type: DISPLAYS_FAILURE, message }
}

export const fetchDisplays = scopes => {
  //localStorage.setItem('displays', null)

  return dispatch => {
    dispatch(requestDisplays())
    let params = []
    for (var scope in scopes) {
      params.push(`${scope}=${scopes[scope]}`)
    }
    return callApi(`/displays?${params.join("&")}`)
      .then(json => dispatch(receiveDisplays(json)))
      .catch(err => {
        dispatch(displaysError(err.message))
        throw err
      })
  }
}


export const fetchDisplaysOnlyVisible = scopes => {
  return dispatch => {
    dispatch(requestDisplays())
    let params = []
    for (var scope in scopes) {
      params.push(`${scope}=${scopes[scope]}`)
    }
    return callApi(`/displays?${params.join("&")}&viewonlyvisible=true`)
      .then(json => dispatch(receiveDisplays(json)))
      .catch(err => {
        dispatch(displaysError(err.message))
        throw err
      })
  }
}


export const fetchDisplaysOnlyEdito = scopes => {
  return dispatch => {
    dispatch(requestDisplays())
    let params = []
    for (var scope in scopes) {
      params.push(`${scope}=${scopes[scope]}`)
    }
    return callApi(`/displays?${params.join("&")}&viewonlyedito=true`)
      .then(json => dispatch(receiveDisplays(json)))
      .catch(err => {
        dispatch(displaysError(err.message))
        throw err
      })
  }
}
