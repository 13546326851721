import React, { Component } from "react"
import styled from "styled-components"
import { Range } from "rc-slider"
import onClickOutside from "react-onclickoutside"

import {vars} from '../variables'
import "rc-slider/assets/index.css"

const SliderWrapper = styled.div`
  display: flex;
  width: 298px;
  flex-direction: column;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  border-top: 2px solid ${vars['blue']};
  padding: 16px 20px;
`

const SliderLabel = styled.div``

const SliderInfo = styled.div`
  padding: 4px 0 24px;
  font-style: italic;
  font-size: 12px;
`

const SliderRange = styled.div`
  padding-top: 32px;
  font-size: 12px;

  > :first-child {
    float: left;
  }
  > :last-child {
    float: right;
  }
`

class TimeSliderComponent extends Component {
  handleClickOutside = evt => {
    this.props.onBlur && this.props.onBlur(evt)
  }

  render() {
    const { show, from, to } = this.props

    // Generate only active marks
    const marks = {
      [from]: `${from}h`,
      [to]: `${to}h`
    }

    return (
      <SliderWrapper
        className="SingleDatePicker_picker"
        style={{ display: show ? "block" : "none" }}
      >
        <SliderLabel>Select a time range</SliderLabel>
        <SliderInfo>maximum 6h</SliderInfo>

        <Range
          dots={false}
          step={1}
          min={0}
          max={24}
          value={[from, to]}
          marks={marks}
          style={{
            borderRight: "1px solid #efefef",
            borderLeft: "1px solid #efefef",
            borderRadius: 0
          }}
          railStyle={{
            backgroundColor: "#dedede",
            height: 1,
            marginTop: 1
          }}
          dotStyle={{
            borderColor: "transparent",
            backgroundColor: "transparent"
          }}
          activeDotStyle={{
            borderColor: "transparent",
            backgroundColor: "transparent"
          }}
          handleStyle={[
            { borderColor: "transparent", backgroundColor: "#4c84ff" },
            { borderColor: "transparent", backgroundColor: "#4c84ff" }
          ]}
          trackStyle={[{ backgroundColor: "#428cf6", height: 3 }]}
          onChange={this.props.onChange}
        />

        <SliderRange>
          <span>00h</span>
          <span>24h</span>
        </SliderRange>
      </SliderWrapper>
    )
  }
}

export default onClickOutside(TimeSliderComponent)
